import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { NgTemplateOutlet, CommonModule } from '@angular/common';
import * as i0 from '@angular/core';
import { Injectable, InjectionToken, Component, ChangeDetectionStrategy, Optional, Inject, Input, NgModule } from '@angular/core';
import * as i1 from '@aposin/ng-aquila/icon';
import { NxIconModule } from '@aposin/ng-aquila/icon';
import * as i2 from '@aposin/ng-aquila/message';
import { NxMessageModule } from '@aposin/ng-aquila/message';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
const _c0 = ["*"];
function NxErrorComponent_Conditional_0_Conditional_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "nx-icon", 2);
  }
  if (rf & 2) {
    i0.ɵɵproperty("name", "exclamation-triangle");
  }
}
function NxErrorComponent_Conditional_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, NxErrorComponent_Conditional_0_Conditional_0_Template, 1, 1, "nx-icon", 2);
    i0.ɵɵelementStart(1, "div", 3)(2, "div", 4);
    i0.ɵɵelementContainer(3, 5);
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    const errorContent_r2 = i0.ɵɵreference(3);
    i0.ɵɵconditional(ctx_r0.showIcon ? 0 : -1);
    i0.ɵɵadvance(2);
    i0.ɵɵattribute("id", ctx_r0.id);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", errorContent_r2);
  }
}
function NxErrorComponent_Conditional_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "nx-message", 1)(1, "div", 4);
    i0.ɵɵelementContainer(2, 5);
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    const errorContent_r2 = i0.ɵɵreference(3);
    i0.ɵɵadvance();
    i0.ɵɵattribute("id", ctx_r0.id);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", errorContent_r2);
  }
}
function NxErrorComponent_ng_template_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵprojection(0);
  }
}
let nextId$1 = 0;
/**
 * Represents the default options for the error notification that can be configured
 * using the `ERROR_DEFAULT_OPTIONS` injection token.
 */
class ErrorDefaultOptions {
  static {
    this.ɵfac = function ErrorDefaultOptions_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || ErrorDefaultOptions)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: ErrorDefaultOptions,
      factory: ErrorDefaultOptions.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ErrorDefaultOptions, [{
    type: Injectable
  }], null, null);
})();
const ERROR_DEFAULT_OPTIONS = new InjectionToken('ERROR_DEFAULT_OPTIONS');
class NxErrorComponent {
  /** Whether an icon should be displayed. Only has an effect for type 'text' */
  set showIcon(value) {
    this._showIcon = coerceBooleanProperty(value);
    this._cdr.markForCheck();
  }
  get showIcon() {
    return this._showIcon;
  }
  /**
   * Id of the nx-error.
   *
   * If not set, the selectable card gets an incremented value by default.
   */
  set id(value) {
    if (value && value !== this._id) {
      this._id = value;
      this._cdr.markForCheck();
    }
  }
  get id() {
    return this._id;
  }
  /**
   * Whether the error should have message or text styling.
   *
   * Default: `'message'`.
   */
  set appearance(value) {
    if (value !== this.appearance) {
      this._appearance = value ? value : 'message';
      this._cdr.markForCheck();
    }
  }
  get appearance() {
    return this._appearance || this._defaultOptions?.appearance || 'message';
  }
  constructor(_cdr, _defaultOptions) {
    this._cdr = _cdr;
    this._defaultOptions = _defaultOptions;
    this._showIcon = true;
    this._id = `nx-error-${nextId$1++}`;
    this._destroyed = new Subject();
    this._defaultOptions?.changes?.pipe(takeUntil(this._destroyed)).subscribe(() => {
      this._cdr.markForCheck();
    });
  }
  ngOnDestroy() {
    this._destroyed.next();
    this._destroyed.complete();
  }
  static {
    this.ɵfac = function NxErrorComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || NxErrorComponent)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(ERROR_DEFAULT_OPTIONS, 8));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: NxErrorComponent,
      selectors: [["nx-error"]],
      hostVars: 3,
      hostBindings: function NxErrorComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵattribute("role", "alert");
          i0.ɵɵclassProp("nx-error--message", ctx.appearance == "message");
        }
      },
      inputs: {
        showIcon: "showIcon",
        id: "id",
        appearance: "appearance"
      },
      ngContentSelectors: _c0,
      decls: 4,
      vars: 2,
      consts: [["errorContent", ""], ["context", "error"], ["aria-hidden", "true", 1, "nx-error__icon", 3, "name"], [1, "nx-error__content-wrapper"], [1, "nx-error__content"], [3, "ngTemplateOutlet"]],
      template: function NxErrorComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵtemplate(0, NxErrorComponent_Conditional_0_Template, 4, 3)(1, NxErrorComponent_Conditional_1_Template, 3, 2, "nx-message", 1)(2, NxErrorComponent_ng_template_2_Template, 1, 0, "ng-template", null, 0, i0.ɵɵtemplateRefExtractor);
        }
        if (rf & 2) {
          i0.ɵɵconditional(ctx.appearance === "text" ? 0 : -1);
          i0.ɵɵadvance();
          i0.ɵɵconditional(ctx.appearance === "message" ? 1 : -1);
        }
      },
      dependencies: [NxIconModule, i1.NxIconComponent, NgTemplateOutlet, NxMessageModule, i2.NxMessageComponent],
      styles: ["[_nghost-%COMP%]{font-size:var(--base-error-simple-font-size);line-height:var(--base-error-simple-line-height);font-weight:var(--base-error-simple-font-weight);letter-spacing:var(--base-error-simple-letter-spacing);color:var(--base-error-color);width:100%;display:flex;text-align:left}[_nghost-%COMP%]   .nx-error__icon[_ngcontent-%COMP%]{font-size:var(--base-error-simple-icon-size);line-height:var(--base-error-simple-line-height);margin-right:8px}@media screen and (forced-colors: active){[_nghost-%COMP%]   .nx-error__icon[_ngcontent-%COMP%]{color:CanvasText}}[_nghost-%COMP%]   .nx-error__content-wrapper[_ngcontent-%COMP%]{max-width:100%}[dir=rtl]   [_nghost-%COMP%]{text-align:right}[dir=rtl]   [_nghost-%COMP%]   .nx-error__icon[_ngcontent-%COMP%]{margin-right:initial;margin-left:8px}"],
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NxErrorComponent, [{
    type: Component,
    args: [{
      selector: 'nx-error',
      changeDetection: ChangeDetectionStrategy.OnPush,
      host: {
        '[attr.role]': '"alert"',
        '[class.nx-error--message]': 'appearance == "message"'
      },
      imports: [NxIconModule, NgTemplateOutlet, NxMessageModule],
      template: "<!-- b2b (expert) error notification -->\n@if (appearance === 'text') {\n    @if (showIcon) {\n        <nx-icon class=\"nx-error__icon\" [name]=\"'exclamation-triangle'\" aria-hidden=\"true\"> </nx-icon>\n    }\n    <div class=\"nx-error__content-wrapper\">\n        <div class=\"nx-error__content\" [attr.id]=\"id\">\n            <ng-container [ngTemplateOutlet]=\"errorContent\"></ng-container>\n        </div>\n    </div>\n}\n\n<!-- b2c error notification -->\n@if (appearance === 'message') {\n    <nx-message context=\"error\">\n        <div class=\"nx-error__content\" [attr.id]=\"id\">\n            <ng-container [ngTemplateOutlet]=\"errorContent\"></ng-container>\n        </div>\n    </nx-message>\n}\n\n<ng-template #errorContent>\n    <ng-content></ng-content>\n</ng-template>\n",
      styles: [":host{font-size:var(--base-error-simple-font-size);line-height:var(--base-error-simple-line-height);font-weight:var(--base-error-simple-font-weight);letter-spacing:var(--base-error-simple-letter-spacing);color:var(--base-error-color);width:100%;display:flex;text-align:left}:host .nx-error__icon{font-size:var(--base-error-simple-icon-size);line-height:var(--base-error-simple-line-height);margin-right:8px}@media screen and (forced-colors: active){:host .nx-error__icon{color:CanvasText}}:host .nx-error__content-wrapper{max-width:100%}[dir=rtl] :host{text-align:right}[dir=rtl] :host .nx-error__icon{margin-right:initial;margin-left:8px}\n"]
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: ErrorDefaultOptions,
    decorators: [{
      type: Optional
    }, {
      type: Inject,
      args: [ERROR_DEFAULT_OPTIONS]
    }]
  }], {
    showIcon: [{
      type: Input
    }],
    id: [{
      type: Input
    }],
    appearance: [{
      type: Input
    }]
  });
})();
class NxErrorModule {
  static {
    this.ɵfac = function NxErrorModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || NxErrorModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: NxErrorModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule, NxIconModule, NxMessageModule, NxErrorComponent]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NxErrorModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, NxIconModule, NxMessageModule, NxErrorComponent],
      exports: [NxErrorComponent]
    }]
  }], null, null);
})();
let nextId = 0;
const DEFAULT_SIZE = 'large';
const LABEL_DEFAULT_OPTIONS = new InjectionToken('LABEL_DEFAULT_OPTIONS');
class NxLabelComponent {
  /** Sets the label to disabled */
  set disabled(value) {
    this._disabled = coerceBooleanProperty(value);
    this._stateChanges.next();
  }
  get disabled() {
    return this._disabled;
  }
  /** Sets the label to disabled */
  set negative(value) {
    this._negative = coerceBooleanProperty(value);
    this._stateChanges.next();
  }
  get negative() {
    return this._negative;
  }
  /** Sets the Id of the label */
  set id(value) {
    this._id = value;
  }
  get id() {
    return this._id;
  }
  /**
   * **Expert option**
   *
   * Sets the appearance of the label.
   */
  set size(value) {
    this._size = value;
    this._stateChanges.next();
  }
  get size() {
    return this._size || this._defaultOptions?.size || DEFAULT_SIZE;
  }
  /**
   * Sets the html `for` attribute on the label.
   */
  set for(value) {
    this._for = value;
    this._cdr.markForCheck();
    this._stateChanges.next();
  }
  get for() {
    return this._for;
  }
  constructor(_defaultOptions, _cdr) {
    this._defaultOptions = _defaultOptions;
    this._cdr = _cdr;
    this._stateChanges = new Subject();
    this._disabled = false;
    this._negative = false;
    this._id = `nx-label-${nextId++}`;
    this._for = null;
  }
  static {
    this.ɵfac = function NxLabelComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || NxLabelComponent)(i0.ɵɵdirectiveInject(LABEL_DEFAULT_OPTIONS, 8), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: NxLabelComponent,
      selectors: [["nx-label"]],
      hostVars: 7,
      hostBindings: function NxLabelComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵattribute("disabled", ctx.disabled);
          i0.ɵɵclassProp("nx-label--negative", ctx.negative)("nx-label--large", ctx.size === "large")("nx-label--small", ctx.size === "small");
        }
      },
      inputs: {
        disabled: "disabled",
        negative: "negative",
        id: "id",
        size: "size",
        for: "for"
      },
      ngContentSelectors: _c0,
      decls: 2,
      vars: 2,
      consts: [[1, "nx-label__content", 3, "for", "id"]],
      template: function NxLabelComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵelementStart(0, "label", 0);
          i0.ɵɵprojection(1);
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          i0.ɵɵproperty("for", ctx.for)("id", ctx.id);
        }
      },
      styles: ["[_nghost-%COMP%]{display:block;color:var(--text-01)}.nx-label--small[_nghost-%COMP%]{font-size:var(--base-label-small-font-size);line-height:var(--base-label-small-line-height);font-weight:var(--base-label-small-font-weight);letter-spacing:var(--base-label-small-letter-spacing)}.nx-label--large[_nghost-%COMP%]{font-size:var(--base-label-large-font-size);line-height:var(--base-label-large-line-height);font-weight:var(--base-label-large-font-weight);letter-spacing:var(--base-label-large-letter-spacing)}[disabled=true][_nghost-%COMP%]   label[_ngcontent-%COMP%]{cursor:not-allowed}.nx-label--negative[_nghost-%COMP%]{color:var(--negative)}"],
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NxLabelComponent, [{
    type: Component,
    args: [{
      selector: 'nx-label',
      changeDetection: ChangeDetectionStrategy.OnPush,
      host: {
        '[attr.disabled]': 'disabled',
        '[class.nx-label--negative]': 'negative',
        '[class.nx-label--large]': 'size === "large"',
        '[class.nx-label--small]': 'size === "small"'
      },
      standalone: true,
      template: "<label class=\"nx-label__content\" [for]=\"for\" [id]=\"id\">\n    <ng-content></ng-content>\n</label>\n",
      styles: [":host{display:block;color:var(--text-01)}:host.nx-label--small{font-size:var(--base-label-small-font-size);line-height:var(--base-label-small-line-height);font-weight:var(--base-label-small-font-weight);letter-spacing:var(--base-label-small-letter-spacing)}:host.nx-label--large{font-size:var(--base-label-large-font-size);line-height:var(--base-label-large-line-height);font-weight:var(--base-label-large-font-weight);letter-spacing:var(--base-label-large-letter-spacing)}:host[disabled=true] label{cursor:not-allowed}:host.nx-label--negative{color:var(--negative)}\n"]
    }]
  }], () => [{
    type: undefined,
    decorators: [{
      type: Optional
    }, {
      type: Inject,
      args: [LABEL_DEFAULT_OPTIONS]
    }]
  }, {
    type: i0.ChangeDetectorRef
  }], {
    disabled: [{
      type: Input
    }],
    negative: [{
      type: Input
    }],
    id: [{
      type: Input
    }],
    size: [{
      type: Input
    }],
    for: [{
      type: Input
    }]
  });
})();
class NxLabelModule {
  static {
    this.ɵfac = function NxLabelModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || NxLabelModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: NxLabelModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NxLabelModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, NxLabelComponent],
      exports: [NxLabelComponent]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { ERROR_DEFAULT_OPTIONS, ErrorDefaultOptions, LABEL_DEFAULT_OPTIONS, NxErrorComponent, NxErrorModule, NxLabelComponent, NxLabelModule };
