import * as crc32 from 'crc-32';

import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup } from '@angular/forms';

import { ConstantsService } from '@app/core/services/base/constants.service';
import { ErrorService } from '@app/core/services/base/error.service';
import { UtilsService } from '@app/core/services/base/utils.service';
import { EventService } from '@app/core/services/event.service';

import { Constants } from '@app/core/constants/constants';

import { UploadFile, UploadInput, UploadOutput, UploaderOptions } from 'ngx-uploader';

@Component({
    selector: 'azd-additional-information',
    templateUrl: './additional-information.component.html',
    styleUrls: ['./additional-information.component.scss'],
    standalone: false,
})
export class AdditionalInformationComponent implements OnInit {
    public constantsFE = Constants;
    public additionalInfoTypeById!: any[];
    public options: UploaderOptions = { concurrency: 1, maxUploads: 3 };
    public uploadInput = new EventEmitter<UploadInput>();

    @Input() _additionalInformationForm!: any;
    @Input() isReadonly!: boolean;

    constructor(
        //
        private constantsService: ConstantsService,
        private utilsService: UtilsService,
        private errorService: ErrorService,
        private formBuilder: FormBuilder,
        private eventService: EventService,
    ) {}

    get additionalInformationForm(): FormGroup {
        return this._additionalInformationForm as FormGroup;
    }

    get additionalInformationChoicesFormArray(): FormArray {
        return this.additionalInformationForm.get('choices') as FormArray;
    }

    getDependenciesFormArrayByChoice(choiceForm: AbstractControl): FormArray {
        return choiceForm.get('dependencies') as FormArray;
    }

    get dependenciesFormArrayByChoice(): FormArray {
        const choiceId = this.additionalInformationForm.get('value')?.value;
        let choiceFormFound!: any;

        if (choiceId !== null) {
            choiceFormFound = this.additionalInformationChoicesFormArray.controls.find(
                (choiceForm: AbstractControl) => choiceForm.get('id')?.value === choiceId,
            );
        }

        if (choiceFormFound) {
            return choiceFormFound.get('dependencies') as FormArray;
        }

        return this.formBuilder.array([]);
    }
    get attachmentFormGroup(): FormGroup {
        return this.additionalInformationForm.get('value') as FormGroup;
    }

    isArray(value: any): boolean {
        return Array.isArray(value);
    }

    ngOnInit(): void {
        this.getConstants();
    }

    getConstants(): void {
        this.constantsService.getConstants().subscribe({
            next: (response: any) => {
                this.additionalInfoTypeById = this.utilsService.arrayToObject(response[Constants.QUESTION_TYPES], 'id');
            },
            error: (error: HttpErrorResponse) => {
                this.errorService.openModalError(error, 'Error fetching Constants');
            },
        });
    }

    onUploadOutputAdditionalInfo(output: UploadOutput): void {
        if (output.type === 'allAddedToQueue') {
        } else if (output.type === 'addedToQueue' && typeof output.file !== 'undefined') {
            if (output.file.size > Constants.MAX_FILE_SIZE) {
                this.attachmentFormGroup.patchValue({
                    size: output.file.size,
                });
            } else {
                this.calculateHash(output.file);
            }
        } else if (output.type === 'rejected' && typeof output.file !== 'undefined') {
            this.attachmentFormGroup.patchValue({
                document: null,
                id: null,
                name: null,
                hash: null,
                size: null,
                filestream: null,
            });
        }
    }

    calculateHash(file: UploadFile): void {
        const fileReader = new FileReader();

        fileReader.onload = (): void => {
            const fileResult = new Uint8Array(<ArrayBuffer>fileReader.result);
            // tslint:disable-next-line:no-bitwise
            const hash = this.utilsService.lpad((crc32.buf(fileResult) >>> 0).toString(16), 8, '0').toUpperCase();

            this.uploadDocument(file, hash);
        };
        fileReader.readAsArrayBuffer(file.nativeFile as Blob);
    }

    uploadDocument(file: UploadFile, hash: string): void {
        const myReader: FileReader = new FileReader();

        myReader.onloadend = (): void => {
            if (myReader.result) {
                const tmpB64String = myReader.result.toString().split(',')[1];
                const fileName = file.name;
                const fileSize = file.size;

                this.attachmentFormGroup.patchValue({
                    document: null,
                    id: null,
                    name: fileName,
                    hash: hash,
                    size: fileSize,
                    filestream: tmpB64String,
                });
            }
        };
        myReader.readAsDataURL(file.nativeFile as Blob);
    }

    removeFile(): void {
        this.uploadInput.emit({ type: 'remove' });
        this.attachmentFormGroup.patchValue({
            document: null,
            id: null,
            name: null,
            hash: null,
            size: null,
            filestream: null,
        });
    }

    getAttachment(): void {
        if (this.attachmentFormGroup.get('filestream')?.value) {
            this.downloadAttachment(this.attachmentFormGroup.get('filestream')?.value);
        } else {
            this.eventService.getAdditionalInfoAttachment(this.attachmentFormGroup.get('id')?.value).subscribe({
                next: (response: any) => {
                    this.downloadAttachment(response.filestream);
                },
                error: (error: HttpErrorResponse) => {
                    this.errorService.openModalError(error, 'Error fetching info attachment');
                },
            });
        }
    }

    downloadAttachment(fileStream: string): void {
        const linkSource = `data:application/octet-stream;base64,${fileStream}`;
        const downloadLink = document.createElement('a');

        downloadLink.href = linkSource;
        downloadLink.style.display = 'hidden';
        downloadLink.id = 'linkDownloadDocument';
        downloadLink.download = this.attachmentFormGroup.get('name')?.value;
        document.body.append(downloadLink);
        document.getElementById('linkDownloadDocument')?.click();
        document.getElementById('linkDownloadDocument')?.remove();
    }
}
