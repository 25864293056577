import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormGroup, UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';

import { NxModalRef, NX_MODAL_DATA } from '@aposin/ng-aquila/modal';

import { AuthService } from '@app/core/services/base/auth.service';
import { ConstantsService } from '@app/core/services/base/constants.service';
import { ErrorService } from '@app/core/services/base/error.service';
import { UtilsService } from '@app/core/services/base/utils.service';
import { EventService } from '@app/core/services/event.service';
import { ExperienceService } from '@app/core/services/experience.service';

import { Constants } from '@app/core/constants/constants';
import { Answer } from '@app/shared/models/additional-information.model';
import { AttachmentModel } from '@app/shared/models/attachment.model';
import { DataModalModel } from '@app/shared/models/data-modal.model';
import { EventModel } from '@app/shared/models/event.model';
import { Companion } from '@app/shared/models/experience.model';
import { LoggedUserModel } from '@app/shared/models/logged-user.model';

import { ModalUtilsComponent } from '../modal-utils/modal-utils.component';

@Component({
    selector: 'azd-onboarding-modal',
    templateUrl: './onboarding-modal.component.html',
    styleUrls: ['./onboarding-modal.component.scss'],
    standalone: false,
})
export class OnboardingModalComponent extends ModalUtilsComponent implements OnInit {
    public participantForm!: FormGroup;
    public numberOptions: number[] = [];
    public currentStep = Constants.ONBOARDING_MODAL_STEP_TICKETS;
    public userData: LoggedUserModel = this.authService.userLocalData;
    public updating = false;
    public currentAdditionalInformationStepIndex = 0;
    public currentCompanionStepIndex = 0;

    get companionFormArray(): FormArray {
        return this.participantForm.get('companions') as FormArray;
    }

    get ticketsNumber(): UntypedFormControl {
        return this.participantForm?.get('requested_tickets') as UntypedFormControl;
    }

    constructor(
        @Inject(NX_MODAL_DATA) public data: DataModalModel,
        protected authService: AuthService,
        protected constantsService: ConstantsService,
        private dialog: NxModalRef<OnboardingModalComponent>,
        protected errorService: ErrorService,
        protected eventService: EventService,
        protected experienceService: ExperienceService,
        protected formBuilder: UntypedFormBuilder,
        protected utilsService: UtilsService,
    ) {
        super(eventService, constantsService, utilsService, errorService, formBuilder, experienceService, authService);

        if (data && Object.keys(data).length !== 0) {
            if (data.event) {
                this.event = data.event;

                if (this.event.experiences.length === 1) {
                    this.experience = this.event.experiences[0];
                }
            }
        }
    }

    getCompanionAdditionalInformationFormArray(companionForm: AbstractControl): FormArray {
        return companionForm.get('additional_information') as FormArray;
    }

    async _getAdditionalInformation(): Promise<void> {
        const params = {
            [this.participation ? 'participant_id' : 'candidate_id']: this.participation ? this.participation.id : this.userData.id,
            event_experience_subscription_participant_type_id: this.participation
                ? this.participation.type_id
                : this.participantTypeByTag[Constants.EVENT_EXPERIENCE_SUBSCRIPTION_PARTICIPANT_TYPES_AZW_EMPLOYEE].id,
            event_experience_id: this.experience.id,
        };

        try {
            await this.getAdditionalInformationAnswers(params);
        } catch (error) {
            this.errorService.openModalError(error, 'Error fetching answers');
        }

        this.createAdditionalInformationForm(false, Constants.QUESTION_SCOPES_ONLY_REFERRERS);
        this.createCompanionFormArray();
    }

    ngOnInit(): void {
        if (this.event?.subscription?.participation) {
            this.participation = this.event.subscription?.participation;
        }

        this.getConstants();
    }

    mappingExtraConstants(response: any): void {
        this.createParticipantForm();

        this._getAdditionalInformation();
    }

    createParticipantForm(): void {
        this.participantForm = this.formBuilder.group({
            requested_tickets: [
                this.event.subscription?.participation ? this.event.subscription?.participation.requested_tickets : null,
                Validators.required,
            ],
            is_base_privacy_signed: [
                this.event.subscription?.participation ? this.event.subscription?.participation.is_base_privacy_signed : false,
                Validators.requiredTrue,
            ],
            additional_information: this.formBuilder.array([]),
            companions: this.formBuilder.array([]),
        });

        this.participantForm.get('requested_tickets')?.valueChanges.subscribe((val: any) => {
            this.createCompanionFormArray();
        });

        this.numberOptions = [
            ...Array(
                this.event.subscription?.participation &&
                    this.event.subscription?.participation.requested_tickets > this.event.per_agent_capacity
                    ? this.event.subscription?.participation.requested_tickets
                    : this.event.per_agent_capacity,
            ).keys(),
        ].map((value: number) => value + 1);
    }

    onSetCurrentStepClick(step: number | undefined = undefined): void {
        if (step === undefined) {
            this.closeModal();
            return;
        }
        this.currentStep = step;
    }

    closeModal(): void {
        this.dialog.close();
    }

    async onOnboardingSubmitClick(): Promise<void> {
        if (this.participantForm?.invalid) {
            this.updating = false;
            return;
        }
        const questions = await this.createAdditionalInformationData();
        const questionsForCompanions = this.createAdditionalInformationForCompanionData();
        let data: any;
        let additionalInfo = {
            questions: questions,
            questions_for_companion: questionsForCompanions,
        };

        data = {
            additional_info: additionalInfo ? additionalInfo : null,
            is_base_privacy_signed: this.participantForm?.get('is_base_privacy_signed')!.value,
            requested_tickets: this.participantForm?.get('requested_tickets')!.value,
            event_experience_id: this.experience!.id,
        };

        this.updating = true;
        if (!this.event?.subscription) {
            this.createEmployeeSubscription(data);
        } else {
            this.editEmployeeParticipation(data);
        }
    }

    createAdditionalInformationForCompanionData(): any {
        let companionsData: any = [];
        this.companionFormArray.controls.forEach((companionForm: AbstractControl) => {
            let companionQuestionsData = companionForm.get('additional_information')?.value.flat(1);

            companionQuestionsData = companionQuestionsData.map((infoData: any) => {
                return {
                    ...infoData,
                    value:
                        this.additionalInfoTypeById[infoData.type_id].tag === Constants.QUESTION_TYPES_NUMERICAL
                            ? +infoData.value
                            : infoData.value,
                };
            });

            companionsData.push({
                companion_index: companionForm.get('companion_index')?.value,
                questions: companionQuestionsData,
            });
        });

        return companionsData;
    }

    onOpenFileClick(): void {
        const privacy = this.event.commercial_structures[0].attachments.find(
            (attachment: AttachmentModel) =>
                this.eventAttachmentById[attachment.type_id].tag === Constants.EVENT_ATTACHMENT_TYPES_DISCLAIMER,
        );

        if (privacy !== undefined) {
            this.eventService.getAttachment(privacy.id).subscribe(
                (response: AttachmentModel) => {
                    const linkSource = `data:application/octet-stream;base64,${response.filestream}`;
                    const downloadLink = document.createElement('a');

                    downloadLink.href = linkSource;
                    downloadLink.download = privacy?.name || 'privacy.pdf';
                    downloadLink.click();
                    downloadLink.remove();
                },
                (error: HttpErrorResponse) => this.errorService.openModalError(error, "Errore nel caricamento dell'allegato"),
            );
        }
    }

    createEmployeeSubscription(data: any): void {
        this.experienceService.sendAgentSubscription(data, this.experience!.id).subscribe(
            (response: EventModel) => {
                this.updating = false;

                this.eventService.updateEvent(response);
                this.closeModal();
            },
            (error: HttpErrorResponse) => {
                this.updating = false;
                this.errorService.openModalError(error, 'Errore nella registrazione ad una experience');
            },
        );
    }

    editEmployeeParticipation(data: any): void {
        if (this.event?.subscription && this.event.subscription.participation) {
            this.experienceService.sendUserSubscription(data, this.event.subscription.participation.id).subscribe(
                (response: EventModel) => {
                    this.updating = false;

                    this.eventService.updateEvent(response);
                    this.closeModal();
                },
                (error: HttpErrorResponse) => {
                    this.updating = false;
                    this.errorService.openModalError(error, 'Errore nella registrazione ad una experience');
                },
            );
        }
    }

    createCompanionFormArray(): void {
        this.companionFormArray.clear();
        if (this.participantForm.get('requested_tickets')?.value !== null) {
            for (let i = 0; i < this.participantForm.get('requested_tickets')?.value - 1; i++) {
                this.companionFormArray.push(this.createAdditionalInformationCompanionFormArray(i));
            }
        }
    }

    createAdditionalInformationCompanionFormArray(i: number): FormGroup {
        let companionForm = this.formBuilder.group({
            companion_index: [i + 1],
            additional_information: this.formBuilder.array([]),
            currentAdditionalInformationStepIndex: [0],
        });

        if (this.experience && this.experience.additional_information_for_companion.length > 0) {
            this.splitCompanionArrayInSteps(companionForm);
        }

        return companionForm;
    }

    nextCompanionAdditionalInformationStep(companionForm: AbstractControl): void {
        companionForm
            .get('currentAdditionalInformationStepIndex')
            ?.setValue(companionForm.get('currentAdditionalInformationStepIndex')?.value + 1);
    }

    backCompanionAdditionalInformationStep(companionForm: AbstractControl): void {
        companionForm
            .get('currentAdditionalInformationStepIndex')
            ?.setValue(companionForm.get('currentAdditionalInformationStepIndex')?.value - 1);
    }

    splitCompanionArrayInSteps(companionForm: FormGroup): void {
        let array = this.formBuilder.array([]);
        let cloneAdditionalInformation = [...this.experience.additional_information_for_companion];

        cloneAdditionalInformation.forEach((dependencyData: any) => {
            let companionAnswer = null;
            if (
                this.participation?.additional_info_answers_for_companion &&
                this.participation?.additional_info_answers_for_companion.length > 0
            ) {
                companionAnswer = this.participation?.additional_info_answers_for_companion.find(
                    (companionData: Companion) => companionData.companion_index === companionForm.get('companion_index')?.value,
                );
                if (companionAnswer) {
                    companionAnswer = companionAnswer.additional_info_answers.find(
                        (answerData: Answer) => answerData.dependency_id === dependencyData?.id,
                    );
                }
            }

            array.push(
                this.createDependenciesForm(
                    dependencyData,
                    false,
                    null,
                    companionAnswer,
                    true,
                    companionForm.get('companion_index')?.value,
                ),
            );
            if (dependencyData.is_new_page_after) {
                (companionForm.get('additional_information') as FormArray).push(array);
                array = this.formBuilder.array([]);
            }
        });
        if (array.controls.length > 0) {
            (companionForm.get('additional_information') as FormArray).push(array);
        }
    }
}
