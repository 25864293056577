import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'bytesToSize',
    standalone: false,
})
export class BytesToSizePipe implements PipeTransform {
    transform(bytes: any): any {
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        if (bytes === 0) {
            return 'n/a';
        }
        const i = Math.floor(Math.log(bytes) / Math.log(1024));
        if (i === 0) {
            return `${bytes} ${sizes[i]}`;
        } else {
            return `${(bytes / 1024 ** i).toFixed(1)} ${sizes[i]}`;
        }
    }
}
