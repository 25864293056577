import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';

import { switchMap } from 'rxjs';

import { AuthService } from '@app/core/services/base/auth.service';
import { ConstantsService } from '@app/core/services/base/constants.service';
import { ErrorService } from '@app/core/services/base/error.service';
import { LocalStorageManagementService } from '@app/core/services/base/local-storage-management.service';
import { UtilsService } from '@app/core/services/base/utils.service';

import { Constants } from '@app/core/constants/constants';
import { ConstantsMapModel, ConstantsModel } from '@app/shared/models/constant.model';
import { LoggedUserModel } from '@app/shared/models/logged-user.model';

@Component({
    selector: 'azd-header-user-detail',
    templateUrl: './header-user-detail.component.html',
    styleUrls: ['./header-user-detail.component.scss'],
    standalone: false,
})
export class HeaderUserDetailComponent implements OnInit {
    public constantsFE = Constants;
    public isAzwAccount = false;
    public roleById!: ConstantsMapModel;
    public userData: LoggedUserModel = this.authService.userLocalData;
    public isDevEnv = false;
    public userProfile: string | null = null;

    constructor(
        private authService: AuthService,
        private constantsService: ConstantsService,
        private utilsService: UtilsService,
        private errorService: ErrorService,
        private localStorageManagementService: LocalStorageManagementService,
    ) {}

    ngOnInit(): void {
        this.isDevEnv = this.utilsService.checkIfDevelopmentEnvironment();

        this.getConstants();
    }

    getConstants(): void {
        this.constantsService.getConstants().subscribe(
            (response: ConstantsModel) => {
                this.roleById = this.utilsService.arrayToObject(response[Constants.USER_ROLES], 'id');

                if (this.userData.role_id !== undefined) {
                    this.userProfile = 'CONSTANTS.' + this.constantsFE.USER_ROLES + '.' + this.roleById[this.userData.role_id].tag;
                } else if (this.userData.is_azw_employee) {
                    this.userProfile = 'CONSTANTS.' + this.constantsFE.USER_ROLES + '.' + this.constantsFE.USER_ROLES_AZW_EMPLOYEE;
                }

                this.isAzwAccount =
                    this.userData.role_id !== undefined &&
                    Constants.USER_ROLES_AGENT_GROUP.concat(Constants.USER_ROLES_ADMIN_GROUP).includes(
                        this.roleById[this.userData.role_id].tag,
                    );
            },
            (error: HttpErrorResponse) => {
                this.errorService.openModalError(error, 'Errore nel caricamento delle constanti');
            },
        );
    }

    onLogoutClick(): void {
        if (this.isAzwAccount) {
            this.authService.azwLogout().subscribe(() => window.location.reload());
        } else {
            this.authService.logout().subscribe(() => window.location.reload());
        }
    }

    switchLogin(azwEmployeeLogin = false): void {
        const loginApi = azwEmployeeLogin ? 'employeeLogin' : 'crpLogin';

        this.localStorageManagementService.deleteTokens();
        this.localStorageManagementService.deleteUser();
        this.localStorageManagementService.deleteLoginType();
        this.authService.clearUserData();

        this.authService[loginApi]()
            .pipe(switchMap(() => this.authService.getUser()))
            .subscribe(() => {
                window.location.reload();
            });
    }
}
