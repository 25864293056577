import * as i0 from '@angular/core';
import { Directive, Component, ChangeDetectionStrategy, Input, ContentChild, NgModule } from '@angular/core';
import * as i1 from '@angular/cdk/a11y';
import { CommonModule } from '@angular/common';
const _c0 = ["*"];
function NxFooterComponent_Conditional_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "nx-footer-copyright");
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate2("\xA9 ", ctx_r0.currentYear, " ", ctx_r0.copyright, "");
  }
}
class NxFooterCopyrightDirective {
  static {
    this.ɵfac = function NxFooterCopyrightDirective_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || NxFooterCopyrightDirective)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: NxFooterCopyrightDirective,
      selectors: [["nx-footer-copyright"]],
      hostAttrs: [1, "nx-footer__copyright"],
      exportAs: ["NxFooterCopyright"]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NxFooterCopyrightDirective, [{
    type: Directive,
    args: [{
      selector: 'nx-footer-copyright',
      exportAs: 'NxFooterCopyright',
      host: {
        class: 'nx-footer__copyright'
      },
      standalone: true
    }]
  }], null, null);
})();
class NxFooterNavigationDirective {
  static {
    this.ɵfac = function NxFooterNavigationDirective_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || NxFooterNavigationDirective)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: NxFooterNavigationDirective,
      selectors: [["nx-footer-navigation"]],
      hostAttrs: ["role", "list", 1, "nx-footer__navigation"],
      exportAs: ["NxFooterNavigation"]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NxFooterNavigationDirective, [{
    type: Directive,
    args: [{
      selector: 'nx-footer-navigation',
      exportAs: 'NxFooterNavigation',
      host: {
        class: 'nx-footer__navigation',
        role: 'list'
      },
      standalone: true
    }]
  }], null, null);
})();
class NxFooterLinkDirective {
  constructor(_elementRef, _focusMonitor) {
    this._elementRef = _elementRef;
    this._focusMonitor = _focusMonitor;
  }
  ngAfterViewInit() {
    this._focusMonitor.monitor(this._elementRef, true);
  }
  ngOnDestroy() {
    this._focusMonitor.stopMonitoring(this._elementRef);
  }
  static {
    this.ɵfac = function NxFooterLinkDirective_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || NxFooterLinkDirective)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i1.FocusMonitor));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: NxFooterLinkDirective,
      selectors: [["nx-footer-link"]],
      hostAttrs: ["role", "listitem", 1, "nx-footer__link"],
      exportAs: ["NxFooterLink"]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NxFooterLinkDirective, [{
    type: Directive,
    args: [{
      selector: 'nx-footer-link',
      exportAs: 'NxFooterLink',
      host: {
        class: 'nx-footer__link',
        role: 'listitem'
      },
      standalone: true
    }]
  }], () => [{
    type: i0.ElementRef
  }, {
    type: i1.FocusMonitor
  }], null);
})();
class NxFooterComponent {
  constructor() {
    this.currentYear = new Date().getFullYear();
  }
  static {
    this.ɵfac = function NxFooterComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || NxFooterComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: NxFooterComponent,
      selectors: [["nx-footer"], ["", "nx-footer", ""]],
      contentQueries: function NxFooterComponent_ContentQueries(rf, ctx, dirIndex) {
        if (rf & 1) {
          i0.ɵɵcontentQuery(dirIndex, NxFooterCopyrightDirective, 7);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx._copyrightDirective = _t.first);
        }
      },
      hostAttrs: ["role", "contentinfo", 1, "nx-footer"],
      inputs: {
        copyright: "copyright"
      },
      ngContentSelectors: _c0,
      decls: 2,
      vars: 1,
      template: function NxFooterComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵtemplate(0, NxFooterComponent_Conditional_0_Template, 2, 2, "nx-footer-copyright");
          i0.ɵɵprojection(1);
        }
        if (rf & 2) {
          i0.ɵɵconditional(ctx.copyright && !ctx._copyrightDirective ? 0 : -1);
        }
      },
      dependencies: [NxFooterCopyrightDirective],
      styles: [".nx-footer[_nghost-%COMP%]{font-size:var(--footer-font-size);line-height:var(--footer-line-height);font-weight:var(--footer-font-weight);letter-spacing:var(--footer-letter-spacing);color:var(--footer-color);display:flex;flex-direction:row;background:transparent;padding:var(--footer-padding-top) 32px var(--footer-padding-bottom) 32px}@media (max-width: 703px){.nx-footer[_nghost-%COMP%]{flex-direction:column-reverse;padding:var(--footer-mobile-padding-top) 16px var(--footer-mobile-padding-bottom) 16px}}  a{text-decoration:none}  .nx-footer__copyright{font-weight:var(--footer-copyright-font-weight);align-self:flex-end;font-size:inherit;line-height:inherit;color:inherit}@media (max-width: 703px){  .nx-footer__copyright{align-self:center;text-align:center}}  .nx-footer__navigation{display:flex;align-self:flex-end}@media (max-width: 703px){  .nx-footer__navigation{text-align:center;align-self:center;flex-direction:column;align-items:center}}@media (max-width: 703px){  .nx-footer__navigation .nx-footer__link{padding-left:0;padding-bottom:16px}[dir=rtl][_nghost-%COMP%]     .nx-footer__navigation .nx-footer__link, [dir=rtl]   [_nghost-%COMP%]     .nx-footer__navigation .nx-footer__link{padding-right:0}}  .nx-footer__link{font-size:inherit;color:inherit;line-height:inherit;padding-left:32px}[dir=rtl][_nghost-%COMP%]     .nx-footer__link, [dir=rtl]   [_nghost-%COMP%]     .nx-footer__link{padding-right:32px;padding-left:initial}  .nx-footer__link a{display:flex;align-items:flex-start}  .nx-footer__link.cdk-keyboard-focused a{border-radius:4px;box-shadow:var(--focus-box-shadow)}@media screen and (forced-colors: active),(forced-colors: active){  .nx-footer__link.cdk-keyboard-focused a{box-shadow:0 0 0 2px background,0 0 0 6px CanvasText;outline:4px solid CanvasText;outline-offset:2px}}"],
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NxFooterComponent, [{
    type: Component,
    args: [{
      selector: 'nx-footer, [nx-footer]',
      changeDetection: ChangeDetectionStrategy.OnPush,
      host: {
        class: 'nx-footer',
        role: 'contentinfo'
      },
      imports: [NxFooterCopyrightDirective],
      template: "@if (copyright && !_copyrightDirective) {\n    <nx-footer-copyright>&copy; {{ currentYear }} {{ copyright }}</nx-footer-copyright>\n}\n\n<ng-content></ng-content>\n",
      styles: [":host(.nx-footer){font-size:var(--footer-font-size);line-height:var(--footer-line-height);font-weight:var(--footer-font-weight);letter-spacing:var(--footer-letter-spacing);color:var(--footer-color);display:flex;flex-direction:row;background:transparent;padding:var(--footer-padding-top) 32px var(--footer-padding-bottom) 32px}@media (max-width: 703px){:host(.nx-footer){flex-direction:column-reverse;padding:var(--footer-mobile-padding-top) 16px var(--footer-mobile-padding-bottom) 16px}}::ng-deep a{text-decoration:none}::ng-deep .nx-footer__copyright{font-weight:var(--footer-copyright-font-weight);align-self:flex-end;font-size:inherit;line-height:inherit;color:inherit}@media (max-width: 703px){::ng-deep .nx-footer__copyright{align-self:center;text-align:center}}::ng-deep .nx-footer__navigation{display:flex;align-self:flex-end}@media (max-width: 703px){::ng-deep .nx-footer__navigation{text-align:center;align-self:center;flex-direction:column;align-items:center}}@media (max-width: 703px){::ng-deep .nx-footer__navigation .nx-footer__link{padding-left:0;padding-bottom:16px}:host-context([dir=rtl]) ::ng-deep .nx-footer__navigation .nx-footer__link{padding-right:0}}::ng-deep .nx-footer__link{font-size:inherit;color:inherit;line-height:inherit;padding-left:32px}:host-context([dir=rtl]) ::ng-deep .nx-footer__link{padding-right:32px;padding-left:initial}::ng-deep .nx-footer__link a{display:flex;align-items:flex-start}::ng-deep .nx-footer__link.cdk-keyboard-focused a{border-radius:4px;box-shadow:var(--focus-box-shadow)}@media screen and (forced-colors: active),(forced-colors: active){::ng-deep .nx-footer__link.cdk-keyboard-focused a{box-shadow:0 0 0 2px background,0 0 0 6px CanvasText;outline:4px solid CanvasText;outline-offset:2px}}\n"]
    }]
  }], null, {
    copyright: [{
      type: Input
    }],
    _copyrightDirective: [{
      type: ContentChild,
      args: [NxFooterCopyrightDirective, {
        static: true
      }]
    }]
  });
})();
class NxFooterModule {
  static {
    this.ɵfac = function NxFooterModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || NxFooterModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: NxFooterModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NxFooterModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, NxFooterComponent, NxFooterCopyrightDirective, NxFooterLinkDirective, NxFooterNavigationDirective],
      exports: [NxFooterComponent, NxFooterCopyrightDirective, NxFooterLinkDirective, NxFooterNavigationDirective]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { NxFooterComponent, NxFooterCopyrightDirective, NxFooterLinkDirective, NxFooterModule, NxFooterNavigationDirective };
