import * as i1 from '@angular/cdk/a11y';
import { A11yModule } from '@angular/cdk/a11y';
import * as i1$1 from '@angular/cdk/overlay';
import { Overlay, OverlayConfig, OverlayModule } from '@angular/cdk/overlay';
import { BasePortalOutlet, CdkPortalOutlet, ComponentPortal, TemplatePortal, PortalModule } from '@angular/cdk/portal';
import * as i0 from '@angular/core';
import { Component, ChangeDetectionStrategy, Optional, Inject, ViewChild, Injectable, InjectionToken, inject, Injector, TemplateRef, SkipSelf, NgModule, Directive } from '@angular/core';
import * as i2$1 from '@angular/router';
import { NavigationEnd, RouterModule } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { Subject, fromEvent } from 'rxjs';
import { ESCAPE, hasModifierKey } from '@angular/cdk/keycodes';
import { takeUntil, filter, map } from 'rxjs/operators';
import * as i2 from '@angular/cdk/bidi';
function NxOverlayContainerComponent_ng_template_0_Template(rf, ctx) {}
const VERTICAL_DIRECTIONS = ['top', 'bottom'];
const HORIZONTAL_DIRECTIONS = ['left', 'right'];
const CLOCKWISE_DIRECTIONS = ['top', 'right', 'bottom', 'left'];
const BASE_OFFSET = 0; // TODO should be 16 once we have a "showArrow" option
/**
 * Configuration for opening a overlay  with the NxDialogService.
 */
class NxOverlayConfig {
  constructor() {
    /** The ARIA role of the overlay element. */
    this.role = null;
    /** Custom class for the overlay pane. */
    this.panelClass = '';
    /** Whether the overlay has a backdrop. */
    this.hasBackdrop = false;
    /** Custom class for the backdrop. */
    this.backdropClass = '';
    /** Whether the user can use escape or clicking on the backdrop to close the overlay. */
    this.closeOnClickOutside = true;
    /** Width of the overlay. */
    this.width = '';
    /** Height of the overlay. */
    this.height = '';
    /** Max-width of the overlay. If a number is provided, assumes pixel units. Defaults to 100vw. */
    this.maxWidth = '';
    /** Position overrides. */
    this.direction = 'bottom';
    /** The fallbacks that are chosen. */
    this.fallbackOrientation = 'clockwise';
    /** ID of the element that describes the overlay. */
    this.ariaDescribedBy = null;
    /** ID of the element that labels the overlay. */
    this.ariaLabelledBy = null;
    /** Aria label to assign to the overlay element. */
    this.ariaLabel = null;
    /** Whether the overlay should focus the first focusable element on open. */
    this.autoFocus = true;
    /**
     * Whether the overlay should restore focus to the
     * previously-focused element, after it's closed.
     */
    this.restoreFocus = true;
    /**
     * Whether the overlay should close when the user goes backwards/forwards in history.
     * Note that this usually doesn't include clicking on links (unless the user is using
     * the `HashLocationStrategy`).
     */
    this.closeOnNavigation = true;
  }
}

/**
 * Throws an exception for the case when a ComponentPortal is
 * attached to a DomPortalOutlet without an origin.
 * @docs-private
 */
function throwNxOverlayContentAlreadyAttachedError() {
  throw Error('Attempting to attach overlay content after content is already attached');
}
/**
 * Internal component that wraps user-provided overlay content.
 */
class NxOverlayContainerComponent extends BasePortalOutlet {
  constructor(_elementRef, _focusTrapFactory, _document, /** The overlay configuration. */
  _config) {
    super();
    this._elementRef = _elementRef;
    this._focusTrapFactory = _focusTrapFactory;
    this._document = _document;
    this._config = _config;
    /** Element that was focused before the overlay was opened. Save this to restore upon close. */
    this._elementFocusedBeforeDialogWasOpened = null;
    /**
     * Attaches a DOM portal to the overlay container.
     * @param portal Portal to be attached.
     * @deprecated To be turned into a method when changed in the CDK.
     */
    this.attachDomPortal = portal => {
      if (this._portalOutlet.hasAttached()) {
        throwNxOverlayContentAlreadyAttachedError();
      }
      this._savePreviouslyFocusedElement();
      return this._portalOutlet.attachDomPortal(portal);
    };
    this._ariaLabelledBy = _config.ariaLabelledBy || null;
  }
  get elementRef() {
    return this._elementRef;
  }
  /**
   * Attach a ComponentPortal as content to this overlay container.
   * @param portal Portal to be attached as the overlay content.
   */
  attachComponentPortal(portal) {
    if (this._portalOutlet.hasAttached()) {
      throwNxOverlayContentAlreadyAttachedError();
    }
    this._savePreviouslyFocusedElement();
    return this._portalOutlet.attachComponentPortal(portal);
  }
  /**
   * Attach a TemplatePortal as content to this overlay container.
   * @param portal Portal to be attached as the overlay content.
   */
  attachTemplatePortal(portal) {
    if (this._portalOutlet.hasAttached()) {
      throwNxOverlayContentAlreadyAttachedError();
    }
    this._trapFocus();
    this._savePreviouslyFocusedElement();
    return this._portalOutlet.attachTemplatePortal(portal);
  }
  /** Moves the focus inside the focus trap. */
  _trapFocus() {
    const element = this._elementRef.nativeElement;
    if (!this._focusTrap) {
      this._focusTrap = this._focusTrapFactory.create(element);
    }
    // If we were to attempt to focus immediately, then the content of the overlay would not yet be
    // ready in instances where change detection has to run first. To deal with this, we simply
    // wait for the microtask queue to be empty.
    if (this._config.autoFocus) {
      this._focusTrap.focusInitialElementWhenReady();
    } else {
      const activeElement = this._document?.activeElement;
      // Otherwise ensure that focus is on the overlay container. It's possible that a different
      // component tried to move focus while the open animation was running. See:
      // https://github.com/angular/components/issues/16215. Note that we only want to do this
      // if the focus isn't inside the overlay already, because it's possible that the consumer
      // turned off `autoFocus` in order to move focus themselves.
      if (activeElement !== element && !element.contains(activeElement)) {
        element.focus();
      }
    }
  }
  /** Restores focus to the element that was focused before the overlay opened. */
  restoreFocus() {
    const toFocus = this._elementFocusedBeforeDialogWasOpened;
    // We need the extra check, because IE can set the `activeElement` to null in some cases.
    if (this._config.restoreFocus && toFocus && typeof toFocus.focus === 'function') {
      const activeElement = this._document?.activeElement;
      const element = this._elementRef.nativeElement;
      // Make sure that focus is still inside the overlay or is on the body (usually because a
      // non-focusable element like the backdrop was clicked) before moving it. It's possible that
      // the consumer moved it themselves before the animation was done, in which case we shouldn't
      // do anything.
      if (!activeElement || activeElement === this._document?.body || activeElement === element || element.contains(activeElement)) {
        toFocus.focus();
      }
    }
    if (this._focusTrap) {
      this._focusTrap.destroy();
    }
  }
  /** Saves a reference to the element that was focused before the overlay was opened. */
  _savePreviouslyFocusedElement() {
    if (!this._document) {
      return;
    }
    this._elementFocusedBeforeDialogWasOpened = this._document.activeElement;
    // Note that there is no focus method when rendering on the server.
    if (this._elementRef.nativeElement.focus) {
      // Move focus onto the overlay immediately in order to prevent the user from accidentally
      // opening multiple overlays at the same time. Needs to be async, because the element
      // may not be focusable immediately.
      Promise.resolve().then(() => this._elementRef.nativeElement.focus());
    }
  }
  static {
    this.ɵfac = function NxOverlayContainerComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || NxOverlayContainerComponent)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i1.ConfigurableFocusTrapFactory), i0.ɵɵdirectiveInject(DOCUMENT, 8), i0.ɵɵdirectiveInject(NxOverlayConfig));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: NxOverlayContainerComponent,
      selectors: [["nx-overlay-container"]],
      viewQuery: function NxOverlayContainerComponent_Query(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵviewQuery(CdkPortalOutlet, 7);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx._portalOutlet = _t.first);
        }
      },
      hostAttrs: [1, "nx-overlay-container"],
      hostVars: 5,
      hostBindings: function NxOverlayContainerComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵattribute("id", ctx._id)("role", ctx._config.role)("aria-labelledby", ctx._config.ariaLabel ? null : ctx._ariaLabelledBy)("aria-label", ctx._config.ariaLabel)("aria-describedby", ctx._config.ariaDescribedBy || null);
        }
      },
      features: [i0.ɵɵInheritDefinitionFeature],
      decls: 1,
      vars: 0,
      consts: [["cdkPortalOutlet", ""]],
      template: function NxOverlayContainerComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵtemplate(0, NxOverlayContainerComponent_ng_template_0_Template, 0, 0, "ng-template", 0);
        }
      },
      dependencies: [CdkPortalOutlet],
      styles: ["[_nghost-%COMP%]{display:block;background-color:transparent;width:100%;height:100%}[_nghost-%COMP%]:focus{outline:none}@media screen and (forced-colors: active){[_nghost-%COMP%]{border:1px solid CanvasText;background:Canvas}}"]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NxOverlayContainerComponent, [{
    type: Component,
    args: [{
      selector: 'nx-overlay-container',
      changeDetection: ChangeDetectionStrategy.Default,
      host: {
        class: 'nx-overlay-container',
        '[attr.id]': '_id',
        '[attr.role]': '_config.role',
        '[attr.aria-labelledby]': '_config.ariaLabel ? null : _ariaLabelledBy',
        '[attr.aria-label]': '_config.ariaLabel',
        '[attr.aria-describedby]': '_config.ariaDescribedBy || null'
      },
      imports: [CdkPortalOutlet],
      template: "<ng-template cdkPortalOutlet></ng-template>\n",
      styles: [":host{display:block;background-color:transparent;width:100%;height:100%}:host:focus{outline:none}@media screen and (forced-colors: active){:host{border:1px solid CanvasText;background:Canvas}}\n"]
    }]
  }], () => [{
    type: i0.ElementRef
  }, {
    type: i1.ConfigurableFocusTrapFactory
  }, {
    type: Document,
    decorators: [{
      type: Optional
    }, {
      type: Inject,
      args: [DOCUMENT]
    }]
  }, {
    type: NxOverlayConfig
  }], {
    _portalOutlet: [{
      type: ViewChild,
      args: [CdkPortalOutlet, {
        static: true
      }]
    }]
  });
})();

// Id counter
let nextId = 0;
/**
 * Reference to a overlay opened via the NxDialogService.
 */
class NxOverlayRef {
  constructor(_overlayRef, _containerInstance, origin, _router, id = `nx-overlay-${nextId++}`) {
    this._overlayRef = _overlayRef;
    this._containerInstance = _containerInstance;
    this.origin = origin;
    this._router = _router;
    this.id = id;
    /** Whether the user is allowed to close the overlay. */
    this.closeOnClickOutside = this._containerInstance._config.closeOnClickOutside;
    /** Subject for notifying the user that the overlay has finished opening. */
    this._afterOpened = new Subject();
    /** Subject for notifying the user that the overlay has finished closing. */
    this._afterClosed = new Subject();
    /** Subject for notifying the user that the overlay has started closing. */
    this._beforeClosed = new Subject();
    /** Current state of the overlay. */
    this._state = 0 /* NxOverlayState.OPEN */;
    this._documentClickObservable = fromEvent(document, 'click');
    _overlayRef.backdropClick().pipe(takeUntil(this._afterClosed)).subscribe(() => {
      if (this.closeOnClickOutside) {
        this.close();
      }
    });
    // We have to defer that or the same click that opened an overlay
    // would immediately close it again.
    setTimeout(() => this.waitForClose());
    this._router.events.pipe(takeUntil(this.afterClosed())).subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.close();
      }
    });
    // Pass the id along to the container.
    _containerInstance._id = id;
    _overlayRef.detachments().pipe(takeUntil(this._afterClosed)).subscribe(() => {
      this._beforeClosed.next(this._result);
      this._beforeClosed.complete();
      this._afterClosed.next(this._result);
      this._afterClosed.complete();
      this.componentInstance = null;
      this._overlayRef.dispose();
    });
    _overlayRef.keydownEvents().pipe(filter(event => event.keyCode === ESCAPE && !hasModifierKey(event)), takeUntil(this._afterClosed)).subscribe(event => {
      event.preventDefault();
      this.close();
    });
    this._afterOpened.next();
    this._afterOpened.complete();
  }
  waitForClose() {
    this._documentClickObservable.pipe(map(event => event.target), filter(target => !this._overlayRef.overlayElement?.contains(target)), takeUntil(this._afterClosed)).subscribe(() => {
      if (this.closeOnClickOutside) {
        this.close();
      }
    });
  }
  /**
   * Close the overlay.
   * @param overlayResult Optional result to return to the overlay opener.
   */
  close(overlayResult) {
    this._result = overlayResult;
    this._beforeClosed.next(overlayResult);
    this._beforeClosed.complete();
    this._overlayRef.dispose();
    this._state = 1 /* NxOverlayState.CLOSED */;
    this._containerInstance.restoreFocus();
    if (this._containerInstance._config.triggerButton) {
      this._containerInstance._config.triggerButton.setTriggerInactive();
    }
  }
  /**
   * Gets an observable that is notified when the overlay is finished opening.
   */
  afterOpened() {
    return this._afterOpened.asObservable();
  }
  /**
   * Gets an observable that is notified when the overlay is finished closing.
   */
  afterClosed() {
    return this._afterClosed.asObservable();
  }
  /**
   * Gets an observable that is notified when the overlay has started closing.
   */
  beforeClosed() {
    return this._beforeClosed.asObservable();
  }
  /**
   * Gets an observable that emits when the overlay's backdrop has been clicked.
   */
  backdropClick() {
    return this._overlayRef.backdropClick();
  }
  /**
   * Gets an observable that emits when keydown events are targeted on the overlay.
   */
  keydownEvents() {
    return this._overlayRef.keydownEvents();
  }
  /** Add a CSS class or an array of classes to the overlay pane. */
  addPanelClass(classes) {
    this._overlayRef.addPanelClass(classes);
    return this;
  }
  /** Remove a CSS class or an array of classes from the overlay pane. */
  removePanelClass(classes) {
    this._overlayRef.removePanelClass(classes);
    return this;
  }
  /** Gets the current state of the overlay's lifecycle. */
  getState() {
    return this._state;
  }
}

/**
 * Creates an error to be thrown if the user provided an invalid popover direction.
 * @docs-private
 */
function getNxOverlayInvalidDirectionError(direction) {
  return Error(`Overlay direction "${direction}" is invalid.`);
}
const OVERLAY_POSITIONS = {
  top(isRtl) {
    return {
      overlayX: 'center',
      overlayY: 'bottom'
    };
  },
  'top-start': function (isRtl) {
    return {
      overlayX: isRtl ? 'end' : 'start',
      overlayY: 'bottom'
    };
  },
  'top-end': function (isRtl) {
    return {
      overlayX: isRtl ? 'start' : 'end',
      overlayY: 'bottom'
    };
  },
  bottom(isRtl) {
    return {
      overlayX: 'center',
      overlayY: 'top'
    };
  },
  'bottom-start': function (isRtl) {
    return {
      overlayX: isRtl ? 'end' : 'start',
      overlayY: 'top'
    };
  },
  'bottom-end': function (isRtl) {
    return {
      overlayX: isRtl ? 'start' : 'end',
      overlayY: 'top'
    };
  },
  left(isRtl) {
    return {
      overlayX: isRtl ? 'start' : 'end',
      overlayY: 'center'
    };
  },
  right(isRtl) {
    return {
      overlayX: isRtl ? 'end' : 'start',
      overlayY: 'center'
    };
  }
};
const ORIGIN_POSITIONS = {
  top(isRtl) {
    return {
      originX: 'center',
      originY: 'top'
    };
  },
  'top-start': function (isRtl) {
    return {
      originX: isRtl ? 'end' : 'start',
      originY: 'top'
    };
  },
  'top-end': function (isRtl) {
    return {
      originX: isRtl ? 'start' : 'end',
      originY: 'top'
    };
  },
  bottom(isRtl) {
    return {
      originX: 'center',
      originY: 'bottom'
    };
  },
  'bottom-start': function (isRtl) {
    return {
      originX: isRtl ? 'end' : 'start',
      originY: 'bottom'
    };
  },
  'bottom-end': function (isRtl) {
    return {
      originX: isRtl ? 'start' : 'end',
      originY: 'bottom'
    };
  },
  left(isRtl) {
    return {
      originX: isRtl ? 'end' : 'start',
      originY: 'center'
    };
  },
  right(isRtl) {
    return {
      originX: isRtl ? 'start' : 'end',
      originY: 'center'
    };
  }
};
class NxOverlayPositionBuilder {
  constructor(_overlay, _dir) {
    this._overlay = _overlay;
    this._dir = _dir;
  }
  createPositionStrategy(element, config) {
    const fallbacks = this._getFallbackPositions(config.direction, config);
    const origin = this.getOrigin(config.direction);
    const overlay = this.getOverlayPosition(config.direction);
    const offset = this.getOffset(config.direction, config);
    return this._overlay.position().flexibleConnectedTo(element).withPush(false).withFlexibleDimensions(true).withLockedPosition().withGrowAfterOpen().withPositions([{
      ...origin,
      ...overlay,
      ...offset
    }, ...fallbacks]);
  }
  /**
   * Returns the origin position based on the user's direction preference.
   */
  getOrigin(direction) {
    if (!direction || !(direction in ORIGIN_POSITIONS)) {
      throw getNxOverlayInvalidDirectionError(direction);
    }
    return ORIGIN_POSITIONS[direction](this.isRtl);
  }
  /** Returns the overlay position based on the user's direction preference */
  getOverlayPosition(direction) {
    if (!direction || !(direction in OVERLAY_POSITIONS)) {
      throw getNxOverlayInvalidDirectionError(direction);
    }
    return OVERLAY_POSITIONS[direction](this.isRtl);
  }
  /** Returns the overlay offset required by the user's direction preference */
  getOffset(direction, config) {
    if (!direction || !(direction in OVERLAY_POSITIONS)) {
      throw getNxOverlayInvalidDirectionError(direction);
    }
    const offset = config.offset || BASE_OFFSET;
    const [genericDirection] = direction.split('-');
    switch (genericDirection) {
      case 'top':
        {
          return {
            offsetY: offset * -1
          };
        }
      case 'bottom':
        {
          return {
            offsetY: offset
          };
        }
      case 'left':
        {
          return {
            offsetX: offset * -1
          };
        }
      case 'right':
        {
          return {
            offsetX: offset
          };
        }
      default:
        {
          throw getNxOverlayInvalidDirectionError(direction);
        }
    }
  }
  /**
   * Returns an array of fallback positions for popover, following the algoritm:
   * 1) Slightly alternate preferred position if applicable. I.e. for 'top' try 'top-start' and 'top-end' positioning.
   * 2) Try the opposite position, i.e. for 'top' try 'bottom'.
   * 3) Slightly alternate opposite position, i.e. 'bottom-start', 'bottom-end'.
   * 4) All remaining positions from positions list.
   */
  _getFallbackPositions(direction, config) {
    if (!direction) {
      return [];
    }
    // create order of fallbacks like: if top then bottom, left, right
    const fallbackOrder = this._getFallbackOrder(direction, config);
    // add positions like {direction}-start and filter the requested direction
    const resolvedDirections = this._resolveFallbacks(fallbackOrder, config).filter(d => d !== direction);
    const fallbackPositions = [];
    resolvedDirections.forEach(fallbackDirection => {
      const origin = this.getOrigin(fallbackDirection);
      const overlay = this.getOverlayPosition(fallbackDirection);
      const offset = this.getOffset(fallbackDirection, config);
      fallbackPositions.push({
        ...origin,
        ...overlay,
        ...offset
      });
    });
    return fallbackPositions;
  }
  /** Takes the defined fallback orders and adjusts it for the requested direction */
  _getFallbackOrder(direction, config) {
    let order;
    switch (config.fallbackOrientation) {
      case 'vertical':
        order = VERTICAL_DIRECTIONS;
        break;
      case 'horizontal':
        order = HORIZONTAL_DIRECTIONS;
        break;
      case 'clockwise':
        order = CLOCKWISE_DIRECTIONS;
        break;
      default:
        throw getNxOverlayInvalidDirectionError(config.fallbackOrientation || '');
    }
    // reorder the array to start from the requested position
    const [generalDirection] = this._splitDirection(direction);
    const directionIndex = order.indexOf(generalDirection);
    return [...order.slice(directionIndex), ...order.slice(0, directionIndex)];
  }
  // We often need the general direction like top or bottom if the requested direction
  // is like bottom-start
  _splitDirection(direction) {
    return direction.split('-');
  }
  get isRtl() {
    return this._dir?.value === 'rtl';
  }
  /** Returns the opposite position, using angular position naming: top, bottom, start, end, center */
  _getInversePosition(position) {
    const positionPairs = {
      top: 'bottom',
      bottom: 'top',
      start: 'end',
      end: 'start',
      center: 'center'
    };
    return positionPairs[position];
  }
  /** Resolve the fallback order to all possible direction. For top and bottom we want to add the start and end positions. */
  _resolveFallbacks(fallbacks, config) {
    if (!config.direction) {
      throw getNxOverlayInvalidDirectionError('');
    }
    const [generalDirection, addition] = this._splitDirection(config.direction);
    return fallbacks.reduce((resolved, direction) => {
      if (direction === 'top' || direction === 'bottom') {
        if (addition) {
          // if we have something like bottom-start we want to do bottom-end first
          resolved.push(`${direction}-${addition}`, `${direction}-${this._getInversePosition(addition)}`, direction);
        } else {
          resolved.push(direction, `${direction}-start`, `${direction}-end`);
        }
      } else {
        resolved.push(direction);
      }
      return resolved;
    }, []);
  }
  static {
    this.ɵfac = function NxOverlayPositionBuilder_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || NxOverlayPositionBuilder)(i0.ɵɵinject(i1$1.Overlay), i0.ɵɵinject(i2.Directionality, 8));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: NxOverlayPositionBuilder,
      factory: NxOverlayPositionBuilder.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NxOverlayPositionBuilder, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1$1.Overlay
  }, {
    type: i2.Directionality,
    decorators: [{
      type: Optional
    }]
  }], null);
})();

/** Injection token that determines the scroll handling while a overlay is open. */
const NX_OVERLAY_SCROLL_STRATEGY = new InjectionToken('nx-overlay-scroll-strategy', {
  providedIn: 'root',
  factory: () => {
    const overlay = inject(Overlay);
    return () => overlay.scrollStrategies.reposition();
  }
});
/** @docs-private */
function NX_OVERLAY_SCROLL_STRATEGY_PROVIDER_FACTORY(overlay) {
  return () => overlay.scrollStrategies.reposition();
}
/** @docs-private */
const NX_OVERLAY_SCROLL_STRATEGY_PROVIDER = {
  provide: NX_OVERLAY_SCROLL_STRATEGY,
  useFactory: NX_OVERLAY_SCROLL_STRATEGY_PROVIDER_FACTORY,
  deps: [Overlay]
};
class NxOverlayService {
  constructor(_overlay, _injector, _router, _positionBuilder, _parentOverlayService, _dir, _defaultScrollStrategyFactory) {
    this._overlay = _overlay;
    this._injector = _injector;
    this._router = _router;
    this._positionBuilder = _positionBuilder;
    this._parentOverlayService = _parentOverlayService;
    this._dir = _dir;
    this._defaultScrollStrategyFactory = _defaultScrollStrategyFactory;
    this._afterAllClosedAtThisLevel = new Subject();
    this._afterOpenedAtThisLevel = new Subject();
    this._openOverlaysAtThisLevel = [];
    /** Strategy factory that will be used to handle scrolling while an overlay panel is open. */
    this._scrollStrategyFactory = this._defaultScrollStrategyFactory;
  }
  /** Keeps track of the currently-open overlays. */
  get openOverlays() {
    return this._parentOverlayService ? this._parentOverlayService.openOverlays : this._openOverlaysAtThisLevel;
  }
  /** The text direction of the containing app. */
  get dir() {
    return this._dir && this._dir.value === 'rtl' ? 'rtl' : 'ltr';
  }
  /**
   * Opens a panel containing the given component and attaches it to an element.
   * @param componentOrTemplateRef Type of the component to load into the dialog, or a TemplateRef to instantiate as the panel content.
   * @param origin Reference element for positioning.
   * @param config Extra configuration options.
   * @returns Reference to the newly-opened panel.
   */
  open(componentOrTemplateRef, origin, config) {
    config = _applyConfigDefaults(config, new NxOverlayConfig());
    if (config.id && this.getOverlayById(config.id)) {
      throw Error(`Overlay with id "${config.id}" exists already. The overlay id must be unique.`);
    }
    const cdkOverlayRef = this._createOverlay(config, origin);
    const overlayContainer = this._attachOverlayContainer(cdkOverlayRef, config);
    const overlayRef = this._attachOverlayContent(componentOrTemplateRef, overlayContainer, cdkOverlayRef, config, origin);
    this.openOverlays.push(overlayRef);
    overlayRef.afterClosed().subscribe(() => this._removeOpenOverlay(overlayRef));
    if (config.triggerButton) {
      config.triggerButton.setTriggerActive();
    }
    return overlayRef;
  }
  /**
   * Finds an open overlay by its id.
   * @param id ID to use when looking up the overlay.
   */
  getOverlayById(id) {
    return this.openOverlays.find(overlay => overlay.id === id);
  }
  ngOnDestroy() {
    // Only close the overlays at this level on destroy
    // since the parent service may still be active.
    this._closeOverlays(this._openOverlaysAtThisLevel);
    this._afterAllClosedAtThisLevel.complete();
    this._afterOpenedAtThisLevel.complete();
  }
  /**
   * Creates the overlay into which the overlay will be loaded.
   * @param config The overlay configuration.
   * @returns A promise resolving to the OverlayRef for the created overlay.
   */
  _createOverlay(config, origin) {
    const overlayConfig = this._getOverlayConfig(config, origin);
    return this._overlay.create(overlayConfig);
  }
  /**
   * Creates a CDK overlay configuration from the overlay service config.
   * @param overlayConfig The nx overlay service configuration.
   * @returns The CDK overlay configuration.
   */
  _getOverlayConfig(overlayConfig, origin) {
    const state = new OverlayConfig({
      positionStrategy: overlayConfig.positionStrategy || this._positionBuilder.createPositionStrategy(origin, overlayConfig),
      scrollStrategy: overlayConfig.scrollStrategy || this._scrollStrategyFactory(),
      panelClass: overlayConfig.panelClass,
      hasBackdrop: overlayConfig.hasBackdrop,
      width: overlayConfig.width,
      height: overlayConfig.height,
      minWidth: overlayConfig.minWidth,
      minHeight: overlayConfig.minHeight,
      maxWidth: overlayConfig.maxWidth,
      maxHeight: overlayConfig.maxHeight,
      disposeOnNavigation: overlayConfig.closeOnNavigation,
      direction: this.dir
    });
    if (overlayConfig.backdropClass) {
      state.backdropClass = overlayConfig.backdropClass;
    }
    return state;
  }
  get isRtl() {
    return this._dir?.value === 'rtl';
  }
  /**
   * Attaches an NxOverlayContainer to the already-created overlay.
   * @param cdkOverlay Reference to the dialog's underlying overlay.
   * @param config The overlay configuration.
   * @returns A promise resolving to a ComponentRef for the attached container.
   */
  _attachOverlayContainer(cdkOverlay, config) {
    const userInjector = config?.viewContainerRef?.injector;
    const injector = Injector.create({
      parent: userInjector || this._injector,
      providers: [{
        provide: NxOverlayConfig,
        useValue: config
      }]
    });
    const containerPortal = new ComponentPortal(NxOverlayContainerComponent, config.viewContainerRef, injector, config.componentFactoryResolver);
    const containerRef = cdkOverlay.attach(containerPortal);
    return containerRef.instance;
  }
  /**
   * Attaches the user-provided component to the already-created NxOverlayContainer.
   * @param componentOrTemplateRef The type of component being loaded into the dialog, or a TemplateRef to instantiate as the content.
   * @param overlayContainer Reference to the wrapping NxOverlayContainer.
   * @param cdkOverlayRef Reference to the overlay in which the overlay resides.
   * @param config The overlay configuration.
   * @returns A promise resolving to the NxOverlayRef that should be returned to the user.
   */
  _attachOverlayContent(componentOrTemplateRef, overlayContainer, cdkOverlayRef, config, origin) {
    // Create a reference to the overlay we're creating in order to give the user a handle
    // to modify and close it.
    const overlayRef = new NxOverlayRef(cdkOverlayRef, overlayContainer, origin, this._router, config.id);
    if (componentOrTemplateRef instanceof TemplateRef) {
      overlayContainer.attachTemplatePortal(new TemplatePortal(componentOrTemplateRef, null, {
        $implicit: overlayRef
      }));
    } else {
      const injector = this._createInjector(config, overlayRef, overlayContainer);
      const contentRef = overlayContainer.attachComponentPortal(new ComponentPortal(componentOrTemplateRef, config.viewContainerRef, injector));
      overlayRef.componentInstance = contentRef.instance;
    }
    return overlayRef;
  }
  /**
   * Creates a custom injector to be used inside the overlay. This allows a component loaded inside
   * of a overlay to close itself and, optionally, to return a value.
   * @param config Config object that is used to construct the overlay.
   * @param overlayRef Reference to the overlay.
   * @param overlayContainer Overlay container element that wraps all of the contents.
   * @returns The custom injector that can be used inside the overlay.
   */
  _createInjector(config, overlayRef, overlayContainer) {
    const userInjector = config?.viewContainerRef?.injector;
    // The NxOverlayContainer is injected in the portal as the NxOverlayContainer and the overlays's
    // content are created out of the same ViewContainerRef and as such, are siblings for injector
    // purposes. To allow the hierarchy that is expected, the NxOverlayContainer is explicitly
    // added to the injection tokens.
    const providers = [{
      provide: NxOverlayContainerComponent,
      useValue: overlayContainer
    }, {
      provide: NxOverlayRef,
      useValue: overlayRef
    }];
    return Injector.create({
      parent: userInjector || this._injector,
      providers
    });
  }
  /**
   * Removes a overlay from the array of open overlays.
   * @param overlayRef Overlay to be removed.
   */
  _removeOpenOverlay(overlayRef) {
    const index = this.openOverlays.indexOf(overlayRef);
    if (index > -1) {
      this.openOverlays.splice(index, 1);
    }
  }
  /** Closes all of the overlays in an array. */
  _closeOverlays(overlays) {
    let i = overlays.length;
    while (i--) {
      // The `_openOverlays` property isn't updated after close until the rxjs subscription
      // runs on the next microtask, in addition to modifying the array as we're going
      // through it. We loop through all of them and call close without assuming that
      // they'll be removed from the list instantaneously.
      overlays[i].close();
    }
  }
  static {
    this.ɵfac = function NxOverlayService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || NxOverlayService)(i0.ɵɵinject(i1$1.Overlay), i0.ɵɵinject(i0.Injector), i0.ɵɵinject(i2$1.Router), i0.ɵɵinject(NxOverlayPositionBuilder), i0.ɵɵinject(NxOverlayService, 12), i0.ɵɵinject(i2.Directionality, 8), i0.ɵɵinject(NX_OVERLAY_SCROLL_STRATEGY));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: NxOverlayService,
      factory: NxOverlayService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NxOverlayService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1$1.Overlay
  }, {
    type: i0.Injector
  }, {
    type: i2$1.Router
  }, {
    type: NxOverlayPositionBuilder
  }, {
    type: NxOverlayService,
    decorators: [{
      type: Optional
    }, {
      type: SkipSelf
    }]
  }, {
    type: i2.Directionality,
    decorators: [{
      type: Optional
    }]
  }, {
    type: undefined,
    decorators: [{
      type: Inject,
      args: [NX_OVERLAY_SCROLL_STRATEGY]
    }]
  }], null);
})();
/**
 * Applies default options to the overlay config.
 * @param config Config to be modified.
 * @param defaultOptions Default options provided.
 * @returns The new configuration object.
 */
function _applyConfigDefaults(config, defaultOptions) {
  return {
    ...defaultOptions,
    ...config
  };
}
class NxOverlayModule {
  static {
    this.ɵfac = function NxOverlayModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || NxOverlayModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: NxOverlayModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      providers: [NxOverlayService, NX_OVERLAY_SCROLL_STRATEGY_PROVIDER],
      imports: [OverlayModule, A11yModule, PortalModule, RouterModule, OverlayModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NxOverlayModule, [{
    type: NgModule,
    args: [{
      imports: [OverlayModule, A11yModule, PortalModule, RouterModule, NxOverlayContainerComponent],
      exports: [NxOverlayContainerComponent, OverlayModule],
      // we need to keep these providers for backwards compatibility
      providers: [NxOverlayService, NX_OVERLAY_SCROLL_STRATEGY_PROVIDER]
    }]
  }], null, null);
})();

/**
 * Interface for any kind of button that should be active while
 * an overlay is open.
 */
class NxTriggerButton {
  static {
    this.ɵfac = function NxTriggerButton_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || NxTriggerButton)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: NxTriggerButton
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NxTriggerButton, [{
    type: Directive,
    args: [{
      standalone: true
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { BASE_OFFSET, CLOCKWISE_DIRECTIONS, HORIZONTAL_DIRECTIONS, NX_OVERLAY_SCROLL_STRATEGY, NX_OVERLAY_SCROLL_STRATEGY_PROVIDER, NX_OVERLAY_SCROLL_STRATEGY_PROVIDER_FACTORY, NxOverlayConfig, NxOverlayContainerComponent, NxOverlayModule, NxOverlayPositionBuilder, NxOverlayRef, NxOverlayService, NxTriggerButton, ORIGIN_POSITIONS, OVERLAY_POSITIONS, VERTICAL_DIRECTIONS, getNxOverlayInvalidDirectionError, throwNxOverlayContentAlreadyAttachedError };
