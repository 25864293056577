import * as crc32 from 'crc-32';

import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { NxModalRef } from '@aposin/ng-aquila/modal';

import { AuthService } from '@app/core/services/base/auth.service';
import { ConstantsService } from '@app/core/services/base/constants.service';
import { ErrorService } from '@app/core/services/base/error.service';
import { UtilsService } from '@app/core/services/base/utils.service';
import { EventService } from '@app/core/services/event.service';
import { ExperienceService } from '@app/core/services/experience.service';

import { Constants } from '@app/core/constants/constants';
import { AdditionalInformation, Answer } from '@app/shared/models/additional-information.model';
import { ConstantsMapModel } from '@app/shared/models/constant.model';
import { EventModel } from '@app/shared/models/event.model';
import { Companion, ExperienceModel } from '@app/shared/models/experience.model';
import { ParticipationModel } from '@app/shared/models/participation.model';

import dayjs from 'dayjs';
import { UploadFile, UploadInput, UploadOutput, UploaderOptions } from 'ngx-uploader';
import { lastValueFrom } from 'rxjs';

@Component({
    selector: 'azd-modal-utils',
    template: '',
    standalone: false,
})
export class ModalUtilsComponent {
    public constantsFE = Constants;
    public experience!: ExperienceModel;
    public event!: EventModel;
    public participantForm!: FormGroup;
    public additionalInfoTypeById!: ConstantsMapModel;
    public eventAttachmentById!: ConstantsMapModel;
    public additionalInfoScopeById!: ConstantsMapModel;
    public participantTypeByTag!: ConstantsMapModel;
    public participantAttachmentTypeByTag!: ConstantsMapModel;
    public participantAttachmentTypes!: ConstantsMapModel[];
    public participation!: ParticipationModel;
    public additionalInformationAnswers: AdditionalInformation[] = [];
    public options: UploaderOptions = { concurrency: 1, maxUploads: 3 };
    public uploadInput = new EventEmitter<UploadInput>();

    constructor(
        protected eventService: EventService,
        protected constantsService: ConstantsService,
        protected utilsService: UtilsService,
        protected errorService: ErrorService,
        protected formBuilder: FormBuilder,
        protected experienceService: ExperienceService,
        protected authService: AuthService,
    ) {}

    get participantAdditionalInformationFormArray(): FormArray {
        return this.participantForm.get('additional_information') as FormArray;
    }

    getConstants(): void {
        this.constantsService.getConstants().subscribe({
            next: (response: any) => {
                this.additionalInfoTypeById = this.utilsService.arrayToObject(response[Constants.QUESTION_TYPES], 'id');
                this.eventAttachmentById = this.utilsService.arrayToObject(response[Constants.EVENT_ATTACHMENT_TYPES], 'id');
                this.additionalInfoScopeById = this.utilsService.arrayToObject(response[Constants.QUESTION_SCOPES], 'id');
                this.participantTypeByTag = this.utilsService.arrayToObject(
                    response[Constants.EVENT_EXPERIENCE_SUBSCRIPTION_PARTICIPANT_TYPES],
                    'tag',
                );
                this.participantAttachmentTypes = response[Constants.EVENT_EXPERIENCE_SUBSCRIPTION_PARTICIPANT_ATTACHMENT_TYPES];
                this.participantAttachmentTypeByTag = this.utilsService.arrayToObject(
                    response[Constants.EVENT_EXPERIENCE_SUBSCRIPTION_PARTICIPANT_ATTACHMENT_TYPES],
                    'tag',
                );

                this.mappingExtraConstants(response);
            },
            error: (error: HttpErrorResponse) => {
                this.errorService.openModalError(error, 'Error fetching Constants');
            },
        });
    }

    // This method is override on child component when we need specific component's constants map.
    mappingExtraConstants(constants: any): void {}

    getStepFormArray(stepFormArray: AbstractControl): FormArray {
        return stepFormArray as FormArray;
    }

    async getAdditionalInformationAnswers(params: any): Promise<void> {
        const { answers } = await this.experienceService.getParticipantAdditionalInformationAnswers(params).toPromise();

        this.additionalInformationAnswers =
            this.participation?.additional_info_answers &&
            this.participation?.additional_info_answers.length > 0 &&
            this.participation.experience_id === params.event_experience_id
                ? this.participation?.additional_info_answers
                : answers
                  ? answers.map((answer: any) => {
                        return {
                            ...answer,
                            id: null,
                        };
                    })
                  : [];
    }

    // ADDITIONAL INFORMATION
    createAdditionalInformationForm(isReadOnly = false, filterBy: string | null = null): void {
        if (this.experience && this.experience.additional_information.length > 0) {
            this.participantAdditionalInformationFormArray.clear();
            this.splitArrayInSteps(filterBy);
        }

        if (isReadOnly) this.participantAdditionalInformationFormArray.disable();
    }

    splitArrayInSteps(filterBy: string | null = null): void {
        let array = this.formBuilder.array<FormGroup>([]);
        let clonedAdditionalInformation = [...this.experience.additional_information];

        if (filterBy) {
            clonedAdditionalInformation = clonedAdditionalInformation.filter(
                (dependencyData: any) => this.additionalInfoScopeById[dependencyData.scope_id].tag !== filterBy,
            );
        }

        clonedAdditionalInformation.forEach((dependencyData: any) => {
            array.push(this.createDependenciesForm(dependencyData, false, filterBy));
            if (dependencyData.is_new_page_after) {
                this.participantAdditionalInformationFormArray.push(array);
                array = this.formBuilder.array<FormGroup>([]);
            }
        });
        if (array.controls.length > 0) {
            this.participantAdditionalInformationFormArray.push(array);
        }
    }

    createDependenciesForm(
        dependencyData: any,
        isDependency = false,
        filterBy: string | null = null,
        companionAnswer: Answer | null = null,
        isForCompanion = false,
        companion_index: number | null = null,
    ): FormGroup {
        const answer = isForCompanion
            ? companionAnswer
            : this.additionalInformationAnswers.find((answerData: any) => answerData.dependency_id === dependencyData?.id);
        const dependencyForm: FormGroup = this.formBuilder.group({
            id: [dependencyData?.id],
            question_id: [dependencyData?.question_id || dependencyData?.id],
            front_office_question: [dependencyData?.front_office_question],
            type_id: [dependencyData?.type_id],
            is_mandatory: [dependencyData?.is_mandatory],
            is_for_companion: [dependencyData?.is_for_companion],
            is_new_page_after: [dependencyData?.is_new_page_after],
            scope_id: [dependencyData?.scope_id],
            choices: this.formBuilder.array([]),
        });

        dependencyForm.addControl(
            'value',
            this.additionalInfoTypeById[dependencyData.type_id].tag === Constants.QUESTION_TYPES_FILE
                ? this.formBuilder.group({
                      document: [],
                      id: [answer?.value ? answer.value.id : null],
                      name: [
                          answer?.value ? answer.value.name : null,
                          dependencyData.is_mandatory && !isDependency ? Validators.required : null,
                      ],
                      hash: [],
                      size: [answer?.value ? answer.value.size : null],
                      filestream: [],
                  })
                : new FormControl(answer?.value, dependencyData.is_mandatory && !isDependency ? Validators.required : null),
        );

        if (answer && answer.id !== null) {
            dependencyForm.addControl('answer_id', new FormControl(answer.id));
        }

        dependencyForm.get('value')?.valueChanges.subscribe((val: any) => {
            (dependencyForm.get('choices') as FormArray).controls.forEach((choiceForm: AbstractControl) => {
                (choiceForm.get('dependencies') as FormArray).controls.forEach((choiceDependencyForm: AbstractControl) => {
                    if (this.additionalInfoTypeById[choiceDependencyForm.get('type_id')?.value].tag === Constants.QUESTION_TYPES_FILE) {
                        choiceDependencyForm.get('value.name')?.setValidators([]);
                        choiceDependencyForm.get('value.name')?.updateValueAndValidity();
                    } else {
                        choiceDependencyForm.get('value')?.setValidators([]);
                        choiceDependencyForm.get('value')?.updateValueAndValidity();
                    }
                });

                if (Array.isArray(val) && val.length > 0) {
                    if (val.includes(choiceForm.get('id')?.value)) {
                        (choiceForm.get('dependencies') as FormArray).controls.forEach((choiceDependencyForm: AbstractControl) => {
                            if (choiceDependencyForm.get('is_mandatory')?.value) {
                                if (
                                    this.additionalInfoTypeById[choiceDependencyForm.get('type_id')?.value].tag ===
                                    Constants.QUESTION_TYPES_FILE
                                ) {
                                    choiceDependencyForm.get('value.name')?.setValidators([Validators.required]);
                                    choiceDependencyForm.get('value.name')?.updateValueAndValidity();
                                } else {
                                    choiceDependencyForm.get('value')?.setValidators([Validators.required]);
                                    choiceDependencyForm.get('value')?.updateValueAndValidity();
                                }
                            }
                        });
                    }
                } else {
                    if (choiceForm.get('id')?.value === val) {
                        (choiceForm.get('dependencies') as FormArray).controls.forEach((choiceDependencyForm: AbstractControl) => {
                            if (choiceDependencyForm.get('is_mandatory')?.value) {
                                if (
                                    this.additionalInfoTypeById[choiceDependencyForm.get('type_id')?.value].tag ===
                                    Constants.QUESTION_TYPES_FILE
                                ) {
                                    choiceDependencyForm.get('value.name')?.setValidators([Validators.required]);
                                    choiceDependencyForm.get('value.name')?.updateValueAndValidity();
                                } else {
                                    choiceDependencyForm.get('value')?.setValidators([Validators.required]);
                                    choiceDependencyForm.get('value')?.updateValueAndValidity();
                                }
                            }
                        });
                    }
                }
            });
        });

        if (dependencyData?.choices.length > 0) {
            for (const choice of dependencyData.choices) {
                (dependencyForm.get('choices') as FormArray).push(this.createChoiceForm(choice, filterBy, companion_index, isForCompanion));
            }
        }

        return dependencyForm;
    }

    createChoiceForm(
        choice: any,
        filterBy: string | null = null,
        companion_index: number | null = null,
        isForCompanion = false,
    ): FormGroup {
        const choiceForm = this.formBuilder.group({
            id: [choice.id],
            label: [choice.label],
            dependencies: this.formBuilder.array([]),
        });

        if (choice?.dependencies.length > 0) {
            let clonedDependencies = [...choice.dependencies];

            if (filterBy) {
                clonedDependencies = clonedDependencies.filter(
                    (dependencyData: any) => this.additionalInfoScopeById[dependencyData.scope_id].tag !== filterBy,
                );
            }
            clonedDependencies.forEach((dependencyData: any) => {
                let companionAnswer = null;
                if (
                    this.participation?.additional_info_answers_for_companion &&
                    this.participation?.additional_info_answers_for_companion.length > 0
                ) {
                    companionAnswer = this.participation?.additional_info_answers_for_companion.find(
                        (companionData: Companion) => companionData.companion_index === companion_index,
                    );
                    if (companionAnswer) {
                        companionAnswer = companionAnswer.additional_info_answers.find(
                            (answerData: Answer) => answerData.dependency_id === dependencyData?.id,
                        );
                    }
                }

                (choiceForm.get('dependencies') as FormArray).push(
                    this.createDependenciesForm(dependencyData, true, null, companionAnswer, isForCompanion, companion_index),
                );
            });
        }

        return choiceForm;
    }

    createAttachmentForm(): FormGroup {
        return this.formBuilder.group({
            document: [],
            documentId: [],
            documentType: [],
            documentName: [],
            documentHash: [],
            documentSize: [],
            documentFileStream: [],
        });
    }

    createAttachmentData(): any[] {
        const attachments: any[] = [];

        this.participantAttachmentTypes.forEach((partAttachType: any) => {
            const fieldName = partAttachType.tag.toLowerCase() + '.disclaimer';
            if (
                this.participantForm.get(partAttachType.tag.toLowerCase() + '.has_disclaimer')?.value === 'si' ||
                this.participantForm.get(partAttachType.tag.toLowerCase() + '.is_mandatory')?.value
            ) {
                if (this.participantForm.get(fieldName + '.documentFileStream')?.value) {
                    attachments.push({
                        type_id: this.participantForm.get(fieldName + '.documentType')?.value,
                        name: this.participantForm.get(fieldName + '.documentName')?.value,
                        size: this.participantForm.get(fieldName + '.documentSize')?.value,
                        hash: this.participantForm.get(fieldName + '.documentHash')?.value,
                        filestream: this.participantForm.get(fieldName + '.documentFileStream')?.value,
                    });
                } else if (this.participantForm.get(fieldName + '.documentId')?.value !== null) {
                    attachments.push({
                        type_id: this.participantForm.get(fieldName + '.documentType')?.value,
                        id: this.participantForm.get(fieldName + '.documentId')?.value,
                        filestream: null,
                    });
                }
            }
        });

        return attachments;
    }

    getInfoDataValue(infoData: any): any {
        switch (this.additionalInfoTypeById[infoData.type_id].tag) {
            case Constants.QUESTION_TYPES_NUMERICAL: {
                return +infoData.value;
            }
            case Constants.QUESTION_TYPES_DATE: {
                return dayjs(infoData.value)?.isValid() ? dayjs(infoData.value).format('YYYY-MM-DD') : null;
            }
            default:
                return infoData.value;
        }
    }

    async getFileInfoDataValue(infoData: any): Promise<any> {
        if (!infoData.value.name) {
            return null;
        } else if (infoData.value.id) {
            const file = await lastValueFrom(this.eventService.getAdditionalInfoAttachment(infoData.value.id));

            return {
                ...infoData.value,
                filestream: file.filestream,
                hash: this.utilsService
                    .lpad((crc32.buf(this.utilsService.base64ToArrayBuffer(file.filestream)) >>> 0).toString(16), 8, '0')
                    .toUpperCase(),
                id: null,
            };
        } else {
            return infoData.value;
        }
    }

    async createAdditionalInformationData(): Promise<any> {
        let formData = this.participantAdditionalInformationFormArray.value.flat(1);
        let data: any[] = [];

        for (const infoData of formData) {
            if (this.additionalInfoTypeById[infoData.type_id].tag === Constants.QUESTION_TYPES_FILE) {
                data.push({
                    ...infoData,
                    value: await this.getFileInfoDataValue(infoData),
                });
            } else {
                data.push({
                    ...infoData,
                    value: this.getInfoDataValue(infoData),
                });
            }
        }

        return data;
    }

    getAttachmentFormValue(fieldName: string): any {
        return this.participantForm.get(fieldName)?.value;
    }

    onUploadOutput(output: UploadOutput, attachmentTypeTag: string): void {
        const fieldName = attachmentTypeTag.toLowerCase() + '.disclaimer';
        const attachmentTypeId = this.participantAttachmentTypeByTag[attachmentTypeTag].id;

        if (output.type === 'allAddedToQueue') {
        } else if (output.type === 'addedToQueue' && typeof output.file !== 'undefined') {
            if (output.file.size > Constants.MAX_FILE_SIZE) {
                this.participantForm.get(fieldName)?.patchValue({
                    documentSize: output.file.size,
                });
            } else {
                this.calculateHash(output.file, fieldName, attachmentTypeId);
            }
        } else if (output.type === 'rejected' && typeof output.file !== 'undefined') {
            this.participantForm.get(fieldName)?.patchValue({
                document: null,
                documentId: null,
                documentType: null,
                documentName: null,
                documentHash: null,
                documentSize: null,
                documentFileStream: null,
            });
        }
    }

    calculateHash(file: UploadFile, fieldName: string, attachmentTypeId: number | null = null): void {
        const fileReader = new FileReader();

        fileReader.onload = (): void => {
            const fileResult = new Uint8Array(<ArrayBuffer>fileReader.result);
            // tslint:disable-next-line:no-bitwise
            const hash = this.utilsService.lpad((crc32.buf(fileResult) >>> 0).toString(16), 8, '0').toUpperCase();

            this.uploadDocument(file, hash, fieldName, attachmentTypeId);
        };
        fileReader.readAsArrayBuffer(file.nativeFile as Blob);
    }

    uploadDocument(file: UploadFile, hash: string, fieldName: string, attachmentTypeId: number | null = null): void {
        const myReader: FileReader = new FileReader();

        myReader.onloadend = (): void => {
            if (myReader.result) {
                const tmpB64String = myReader.result.toString().split(',')[1];
                const fileName = file.name;
                const fileSize = file.size;

                this.participantForm.get(fieldName)?.patchValue({
                    document: null,
                    documentId: null,
                    documentType: attachmentTypeId,
                    documentName: fileName,
                    documentHash: hash,
                    documentSize: fileSize,
                    documentFileStream: tmpB64String,
                });
            }
        };
        myReader.readAsDataURL(file.nativeFile as Blob);
    }

    removeFile(fieldName: string): void {
        this.uploadInput.emit({ type: 'remove' });
        this.participantForm.get(fieldName)?.patchValue({
            document: null,
            documentId: null,
            documentType: null,
            documentName: null,
            documentHash: null,
            documentSize: null,
            documentFileStream: null,
        });
    }

    getAttachment(fieldName: string): void {
        if (this.participantForm.get(fieldName + '.documentFileStream')?.value) {
            this.downloadAttachment(
                this.participantForm.get(fieldName + '.documentFileStream')?.value,
                this.participantForm.get(fieldName + '.documentName')?.value,
            );
        } else {
            this.eventService.getParticipantAttachment(this.participantForm.get(fieldName + '.documentId')?.value).subscribe(
                (response: any) => {
                    this.downloadAttachment(response.filestream, this.participantForm.get(fieldName + '.documentName')?.value);
                },
                (error: HttpErrorResponse) => {
                    this.errorService.openModalError(error, 'Error fetching Participant attachment');
                },
            );
        }
    }

    downloadAttachment(fileStream: string, fileName: string): void {
        const linkSource = `data:application/octet-stream;base64,${fileStream}`;
        const downloadLink = document.createElement('a');

        downloadLink.href = linkSource;
        downloadLink.style.display = 'hidden';
        downloadLink.id = 'linkDownloadDocument';
        downloadLink.download = fileName;
        document.body.append(downloadLink);
        document.getElementById('linkDownloadDocument')?.click();
        document.getElementById('linkDownloadDocument')?.remove();
    }

    toggleModal(dialog: NxModalRef<any>): void {
        dialog.close();
    }
}
