import { TranslatePipe } from '@ngx-translate/core';

import { HttpErrorResponse } from '@angular/common/http';
import { Pipe, PipeTransform } from '@angular/core';

import { ConstantsService } from '@app/core/services/base/constants.service';
import { ErrorService } from '@app/core/services/base/error.service';
import { UtilsService } from '@app/core/services/base/utils.service';

import { Constants } from '@app/core/constants/constants';

@Pipe({
    name: 'sortEventTypes',
    standalone: false,
})
export class SortEventTypesPipe implements PipeTransform {
    public constantsFE = Constants;
    public eventTypeById: any;

    constructor(
        private utilsService: UtilsService,
        private constantsService: ConstantsService,
        private errorService: ErrorService,
        private translatePipe: TranslatePipe,
    ) {}

    transform(value: any): any {
        if (!value) {
            return value;
        }

        let oTypes = [];
        let returnData;

        if (typeof value[0] === 'number') {
            this.constantsService.getConstants().subscribe({
                next: (response: any) => {
                    this.eventTypeById = this.utilsService.arrayToObject(response[Constants.EVENT_TYPES], 'id');
                },
                error: (error: HttpErrorResponse) => {
                    this.errorService.openModalError(error, 'Errore nel caricamento delle constanti');
                },
            });

            oTypes = value.map((type: string, index: number) => ({
                value: index,
                label: this.translatePipe.transform('CONSTANTS.' + this.constantsFE.EVENT_TYPES + '.' + this.eventTypeById[type].tag),
            }));
            const oTypesSorted = this.utilsService.sortArray(oTypes, 'label');
            returnData = oTypesSorted.map((o: any) => o.value);
        } else {
            oTypes = value.map((type: any) => ({
                ...type,
                label: this.translatePipe.transform('CONSTANTS.' + this.constantsFE.EVENT_TYPES + '.' + type.tag),
            }));
            const oTypesSorted = this.utilsService.sortArray(oTypes, 'label');
            returnData = oTypesSorted;
        }

        return returnData;
    }
}
