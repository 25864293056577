import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { switchMap } from 'rxjs/operators';

import { NxDialogService } from '@aposin/ng-aquila/modal';

import { AuthService } from '@app/core/services/base/auth.service';
import { ErrorService } from '@app/core/services/base/error.service';

import { Constants } from '@app/core/constants/constants';

@Component({
    selector: 'azd-login-modal',
    templateUrl: './login-modal.component.html',
    styleUrls: ['./login-modal.component.scss'],
    standalone: false,
})
export class LoginModalComponent implements OnInit {
    public constantsFE = Constants;
    public currentStep = 1;
    public modalForm!: UntypedFormGroup;
    public loading = false;

    constructor(
        private errorService: ErrorService,
        private dialogService: NxDialogService,
        private formBuilder: UntypedFormBuilder,
        private authService: AuthService,
    ) {}

    ngOnInit(): void {
        this.createForm();
    }

    createForm(): void {
        this.modalForm = this.formBuilder.group({
            username: [null, Validators.required],
            password: [null, Validators.required],
            remember_me: [],
        });
    }

    onLoginClick(isEmployee = false): void {
        if (this.modalForm.invalid) {
            return;
        }

        this.loading = true;

        isEmployee ? this.loginAsEmployee() : this.loginAsAgent();
    }

    loginAsAgent(): void {
        this.authService
            .azwLogin(
                this.modalForm.get('username')?.value,
                this.modalForm.get('password')?.value,
                this.modalForm.get('remember_me')?.value,
            )
            .pipe(switchMap(() => this.authService.getUser()))
            .subscribe(
                () => {
                    this.closeModal();
                    this.loading = false;
                    window.location.href = window.location.href.split('?')[0];
                },
                (error: HttpErrorResponse) => {
                    this.loading = false;
                    this.errorService.openModalError(error, 'Errore nella login');
                },
            );
    }

    loginAsEmployee(): void {
        this.authService
            .loginAsEmployee(
                this.modalForm.get('username')?.value,
                this.modalForm.get('password')?.value,
                this.modalForm.get('remember_me')?.value,
            )
            .pipe(switchMap(() => this.authService.getUser()))
            .subscribe(
                () => {
                    this.closeModal();
                    this.loading = false;
                    window.location.href = window.location.href.split('?')[0];
                },
                (error: HttpErrorResponse) => {
                    this.loading = false;
                    this.errorService.openModalError(error, 'Errore nella login');
                },
            );
    }

    closeModal(): void {
        this.dialogService.closeAll();
    }
}
