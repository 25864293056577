import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';

import { NxDialogService, NxModalRef } from '@aposin/ng-aquila/modal';

import { AuthService } from '@app/core/services/base/auth.service';
import { ConstantsService } from '@app/core/services/base/constants.service';
import { ErrorService } from '@app/core/services/base/error.service';
import { UtilsService } from '@app/core/services/base/utils.service';

import { Constants } from '@app/core/constants/constants';
import { LoginModalComponent } from '@app/shared/components/login-modal/login-modal.component';
import { ProfileModalComponent } from '@app/shared/components/profile-modal/profile-modal.component';
import { ConstantsMapModel, ConstantsModel } from '@app/shared/models/constant.model';
import { LoggedUserModel } from '@app/shared/models/logged-user.model';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
    selector: 'azd-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss', './header-mobile.component.scss'],
    standalone: false,
})
export class HeaderComponent implements OnInit {
    public menuOpen = false;
    public profileModal!: NxModalRef<ProfileModalComponent>;
    public loginModal!: NxModalRef<LoginModalComponent>;
    public userData: LoggedUserModel = this.authService.userLocalData;
    public constantsFE = Constants;
    public roleById!: ConstantsMapModel;
    public isAzwAccount = false;
    public isDevEnv = false;

    @Input() isBlankLayout!: boolean;
    constructor(
        private authService: AuthService,
        private dialogService: NxDialogService,
        private activatedRoute: ActivatedRoute,
        private constantsService: ConstantsService,
        private utilsService: UtilsService,
        private errorService: ErrorService,
    ) {}

    ngOnInit(): void {
        this.isDevEnv = this.utilsService.checkIfDevelopmentEnvironment();
        if (!this.isBlankLayout) {
            if (this.userData) {
                this.getConstants();
            }

            this.activatedRoute.queryParams.subscribe((params: Params) => {
                if (params.signup) {
                    this.onShowProfileModalClick(params.emailConfirmed, params.resetPassword);
                }
            });
        }
    }

    getConstants(): void {
        this.constantsService.getConstants().subscribe(
            (response: ConstantsModel) => {
                this.roleById = this.utilsService.arrayToObject(response[Constants.USER_ROLES], 'id');

                this.isAzwAccount =
                    this.userData.role_id !== undefined &&
                    Constants.USER_ROLES_AGENT_GROUP.concat(Constants.USER_ROLES_ADMIN_GROUP).includes(
                        this.roleById[this.userData.role_id].tag,
                    );
            },
            (error: HttpErrorResponse) => {
                this.errorService.openModalError(error, 'Errore nel caricamento delle constanti');
            },
        );
    }

    onShowMenuClick(): void {
        this.menuOpen = !this.menuOpen;
    }

    onShowProfileModalClick(emailConfirmed: boolean = false, resetPassword: boolean = false): void {
        if (!this.userData || emailConfirmed) {
            let data;

            data = {
                showCloseIcon: true,
                maxWidth: 550,
            };
            if (emailConfirmed) {
                data = {
                    ...data,
                    data: {
                        step: Constants.PROFILE_MODAL_STEP_SUCCESS,
                    },
                };
            }

            if (resetPassword) {
                data = {
                    ...data,
                    data: {
                        step: Constants.PROFILE_MODAL_STEP_RESET_PASSWORD_SUCCESS,
                    },
                };
            }
            this.profileModal = this.dialogService.open(ProfileModalComponent, data);
        }
    }

    onShowLoginModalClick(): void {
        this.loginModal = this.dialogService.open(LoginModalComponent, {
            showCloseIcon: true,
            maxWidth: 650,
        });
    }
}
