import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';

import { NX_MODAL_DATA, NxModalRef } from '@aposin/ng-aquila/modal';

import { AuthService } from '@app/core/services/base/auth.service';
import { ConstantsService } from '@app/core/services/base/constants.service';
import { ErrorService } from '@app/core/services/base/error.service';
import { LocalStorageManagementService } from '@app/core/services/base/local-storage-management.service';
import { UtilsService } from '@app/core/services/base/utils.service';
import { EventService } from '@app/core/services/event.service';
import { ExperienceService } from '@app/core/services/experience.service';

import { Constants } from '@app/core/constants/constants';
import { AgencyModel } from '@app/shared/models/agency.model';
import { AttachmentModel } from '@app/shared/models/attachment.model';
import { ConstantsMapModel } from '@app/shared/models/constant.model';
import { DataModalModel } from '@app/shared/models/data-modal.model';
import { EventModel } from '@app/shared/models/event.model';
import { ExperienceModel } from '@app/shared/models/experience.model';
import { LoggedUserModel } from '@app/shared/models/logged-user.model';

import { ModalUtilsComponent } from '../modal-utils/modal-utils.component';

@Component({
    selector: 'azd-experience-registration-modal',
    templateUrl: './experience-registration-modal.component.html',
    styleUrls: ['./experience-registration-modal.component.scss'],
    standalone: false,
})
export class ExperienceRegistrationModalComponent extends ModalUtilsComponent implements OnInit {
    public currentStep!: number;
    public userData: LoggedUserModel = this.authService.userLocalData;
    public updating = false;
    public roleById!: ConstantsMapModel;
    public eventAttachmentByTag!: ConstantsMapModel;
    public experienceAttachmentById!: ConstantsMapModel;
    public eventTargetById!: ConstantsMapModel;
    public participantInvitationStatusById!: ConstantsMapModel;
    public eventAttachmentTypeById!: ConstantsMapModel;
    public eventAttachmentTypeByTag!: ConstantsMapModel;
    public eventStatusById!: ConstantsMapModel;
    public commercialStructureById!: ConstantsMapModel;
    public commercialStructureByTag!: ConstantsMapModel;
    public isAgent = false;
    public isNetworkEvent = false;
    public isEditInvitationMode = false;
    public agencies: AgencyModel[] = [];
    public isEmployee = false;
    public isFromExperienceCard = false;
    public eventTypeById!: ConstantsMapModel;
    public isTravelEvent = false;
    public travelCompanionshipStatuses!: any;
    public travelCompanionshipStatusById!: ConstantsMapModel;
    public travelCompanionshipStatusByTag!: ConstantsMapModel;
    public participantAttachmentTypeById!: ConstantsMapModel;
    public disableContinueButton: boolean = false;
    public currentAdditionalInformationStepIndex = 0;

    constructor(
        @Inject(NX_MODAL_DATA) public data: DataModalModel,
        protected constantsService: ConstantsService,
        protected utilsService: UtilsService,
        protected errorService: ErrorService,
        protected formBuilder: UntypedFormBuilder,
        protected eventService: EventService,
        protected experienceService: ExperienceService,
        private dialog: NxModalRef<ExperienceRegistrationModalComponent>,
        protected authService: AuthService,
        private localStorageService: LocalStorageManagementService,
        private changeDetectorRef: ChangeDetectorRef,
    ) {
        super(eventService, constantsService, utilsService, errorService, formBuilder, experienceService, authService);
        if (data && Object.keys(data).length !== 0) {
            if (data.experience) {
                this.experience = data.experience;
            }
            if (data.event) {
                this.event = data.event;

                if (this.event.experiences.length === 1) {
                    this.experience = this.event.experiences[0];
                }
            }
            if (data.isEditInvitationMode) {
                this.isEditInvitationMode = data.isEditInvitationMode;
            }
            if (data.isFromExperienceCard) {
                this.isFromExperienceCard = data.isFromExperienceCard;
            }
        }
    }

    get userCommercialStructure(): any {
        return this.event.commercial_structures.find((cs: any) => {
            if (this.isAgent || this.isEmployee) {
                return this.commercialStructureById[cs.id].tag === Constants.COMMERCIAL_STRUCTURES_AZ;
            } else {
                return this.commercialStructureById[cs.id].tag === Constants.COMMERCIAL_STRUCTURES_AZB;
            }
        });
    }

    get invalidTravelAttachments(): boolean {
        return this.isTravelEvent
            ? this.getTravelCompanionshipStatusAttachments().some(
                  (attachment: string) =>
                      this.participantForm.get(`${attachment.toLowerCase()}.is_mandatory`)?.value &&
                      this.participantForm.get(`${attachment.toLowerCase()}.disclaimer.documentName`)?.value === null,
              )
            : false;
    }

    get isRegisterDisabled(): boolean {
        if (this.isTravelEvent && !this.participantForm.value.is_participating) {
            return (
                this.getTravelCompanionshipStatusAttachments().some(
                    (attachment: string) =>
                        this.participantForm.get(`${attachment.toLowerCase()}.is_mandatory`)?.value &&
                        this.participantForm.get(`${attachment.toLowerCase()}.disclaimer.documentName`)?.value === null,
                ) || this.updating
            );
        } else {
            return this.invalidTravelAttachments || this.updating;
        }
    }

    async _getAdditionalInformation(): Promise<void> {
        const params = {
            [this.participation ? 'participant_id' : 'candidate_id']: this.participation ? this.participation.id : this.userData.id,
            event_experience_subscription_participant_type_id: this.participation ? this.participation.type_id : this.getTypeIdFromRoleId(),
            event_experience_id: this.experience.id,
        };

        try {
            await this.getAdditionalInformationAnswers(params);
        } catch (error) {
            this.errorService.openModalError(error, 'Error fetching answers');
        }

        this.createAdditionalInformationForm(false, Constants.QUESTION_SCOPES_ONLY_REFERRERS);
    }

    ngOnInit(): void {
        this.getConstants();
    }

    mappingExtraConstants(response: any): void {
        this.commercialStructureById = this.utilsService.arrayToObject(response[Constants.COMMERCIAL_STRUCTURES], 'id');
        this.commercialStructureByTag = this.utilsService.arrayToObject(response[Constants.COMMERCIAL_STRUCTURES], 'tag');
        this.roleById = this.utilsService.arrayToObject(response[Constants.USER_ROLES], 'id');
        this.eventAttachmentByTag = this.utilsService.arrayToObject(response[Constants.EVENT_ATTACHMENT_TYPES], 'tag');
        this.experienceAttachmentById = this.utilsService.arrayToObject(response[Constants.EVENT_EXPERIENCE_ATTACHMENT_TYPES], 'id');
        this.eventTargetById = this.utilsService.arrayToObject(response[Constants.EVENT_TARGETS], 'id');
        this.eventStatusById = this.utilsService.arrayToObject(response[Constants.EVENT_STATUSES], 'id');
        this.eventTypeById = this.utilsService.arrayToObject(response[Constants.EVENT_TYPES], 'id');
        this.participantInvitationStatusById = this.utilsService.arrayToObject(
            response[Constants.EVENT_EXPERIENCE_SUBSCRIPTION_PARTICIPANT_INVITATION_STATUSES],
            'id',
        );
        this.eventAttachmentTypeById = this.utilsService.arrayToObject(response[Constants.EVENT_ATTACHMENT_TYPES], 'id');
        this.eventAttachmentTypeByTag = this.utilsService.arrayToObject(response[Constants.EVENT_ATTACHMENT_TYPES], 'tag');
        this.participantAttachmentTypeById = this.utilsService.arrayToObject(
            response[Constants.EVENT_EXPERIENCE_SUBSCRIPTION_PARTICIPANT_ATTACHMENT_TYPES],
            'id',
        );
        this.travelCompanionshipStatuses = this.utilsService.sortReverseArray(
            response[Constants.EVENT_SUBSCRIPTION_SUBSCRIBER_COMPANIONSHIP_STATUSES],
            'id',
        );
        this.travelCompanionshipStatusById = this.utilsService.arrayToObject(this.travelCompanionshipStatuses, 'id');
        this.travelCompanionshipStatusByTag = this.utilsService.arrayToObject(this.travelCompanionshipStatuses, 'tag');

        if (this.event.subscription?.participation) {
            this.participation = this.event.subscription?.participation;
        }

        this.isNetworkEvent = Constants.EVENT_TARGETS_NETWORK_GROUP.includes(this.eventTargetById[this.event.target_id].tag);
        this.isTravelEvent = this.eventTargetById[this.event.target_id].tag === Constants.EVENT_TARGETS_TRAVEL;
        this.isAgent =
            this.userData.role_id !== undefined && Constants.USER_ROLES_AGENT_GROUP.includes(this.roleById[this.userData.role_id].tag);
        this.isEmployee = this.localStorageService.getLoginType() === 'employee';
        this.currentStep =
            this.isNetworkEvent || (this.isAgent && this.event.subscription)
                ? Constants.EXPERIENCE_REGISTRATION_MODAL_STEP_PARTICIPATION
                : this.event.experiences.length > 1 && !this.isFromExperienceCard
                  ? Constants.EXPERIENCE_REGISTRATION_MODAL_STEP_EXPERIENCES
                  : (this.isTravelEvent || this.agencies.length > 1) && this.isAgent
                    ? Constants.EXPERIENCE_REGISTRATION_MODAL_STEP_PRIVACY
                    : Constants.EXPERIENCE_REGISTRATION_MODAL_STEP_PRIVACY_INTERNAL;

        this.createModalForm();

        if (this.userData.role_id !== undefined && this.roleById[this.userData.role_id].tag === Constants.USER_ROLES_AZ_AGENT) {
            this.getAgencies();
        }

        if (this.experience) {
            this._getAdditionalInformation();
        }
    }

    createModalForm(): void {
        this.participantForm = this.formBuilder.group({
            additional_information: this.formBuilder.array([]),
            companionship_status_id: [
                this.event.subscription?.subscriber?.companionship_status_id !== null
                    ? this.event.subscription?.subscriber?.companionship_status_id
                    : null,
                this.isTravelEvent && this.isAgent ? Validators.required : null,
            ],
            privacy: this.formBuilder.group({
                is_base_privacy_signed: [
                    this.event.subscription?.participation ? this.event.subscription?.participation.is_base_privacy_signed : false,
                    this.event.needs_base_privacy ? Validators.requiredTrue : null,
                ],
                is_health_privacy_signed: [
                    this.event.subscription?.participation ? this.event.subscription?.participation.is_health_privacy_signed : false,
                    this.event.needs_health_privacy ? Validators.requiredTrue : null,
                ],
                is_image_privacy_signed: [
                    this.event.subscription?.participation ? this.event.subscription?.participation.is_image_privacy_signed : false,
                ],
            }),
            is_participating: [
                this.event.subscription?.participation && this.isNetworkEvent
                    ? this.getIsParticipating(this.event.subscription?.participation)
                    : this.event.subscription?.participation !== null,
                this.isNetworkEvent || this.isAgent ? Validators.required : null,
            ],
            experience_id: [this.experience ? this.experience.id : null, Validators.required],
            agency_id: [],
        });

        if (this.isTravelEvent && this.isAgent) {
            this.participantForm.get('companionship_status_id')?.valueChanges.subscribe((val: any) => {
                this.configureAttachments();

                this.participantForm
                    .get('is_participating')
                    ?.setValue(
                        val !==
                            this.travelCompanionshipStatusByTag[
                                Constants.EVENT_SUBSCRIPTION_SUBSCRIBER_COMPANIONSHIP_STATUSES_NOT_PARTICIPATING
                            ].id,
                    );
            });
        }

        [
            Constants.EVENT_EXPERIENCE_SUBSCRIPTION_PARTICIPANT_ATTACHMENT_TYPES_WINNING_DECLARATION,
            Constants.EVENT_EXPERIENCE_SUBSCRIPTION_PARTICIPANT_ATTACHMENT_TYPES_TOTAL_RENOUNCE_DECLARATION,
            Constants.EVENT_EXPERIENCE_SUBSCRIPTION_PARTICIPANT_ATTACHMENT_TYPES_NON_PARTICIPATION_DECLARATION,
            Constants.EVENT_EXPERIENCE_SUBSCRIPTION_PARTICIPANT_ATTACHMENT_TYPES_PARTIAL_RENOUNCE_DECLARATION,
            Constants.EVENT_EXPERIENCE_SUBSCRIPTION_PARTICIPANT_ATTACHMENT_TYPES_PARTIAL_NON_PARTICIPATION_DECLARATION,
        ].forEach((partAttachType: string) => {
            this.participantForm.addControl(
                partAttachType.toLowerCase(),
                this.formBuilder.group({
                    disclaimer: this.createAttachmentForm(),
                    is_mandatory: [false],
                }),
            );
        });

        if (this.event.subscription?.subscriber?.companionship_status_id !== null && this.isAgent) {
            this.configureAttachments();
            if (this.event.subscription?.participation) {
                this.populateAttachments(this.event.subscription?.participation);
            }
        }

        this.participantForm.get('is_participating')?.valueChanges.subscribe((value: any) => {
            if (value) {
                this.participantForm
                    .get('privacy.is_base_privacy_signed')
                    ?.setValidators(this.event.needs_base_privacy ? [Validators.requiredTrue] : []);
                this.participantForm
                    .get('privacy.is_health_privacy_signed')
                    ?.setValidators(this.event.needs_health_privacy ? [Validators.requiredTrue] : []);
            } else {
                this.participantForm.get('privacy.is_base_privacy_signed')?.setValidators([]);
                this.participantForm.get('privacy.is_health_privacy_signed')?.setValidators([]);
            }
            this.participantForm.get('privacy.is_base_privacy_signed')?.updateValueAndValidity();
            this.participantForm.get('privacy.is_health_privacy_signed')?.updateValueAndValidity();
        });

        if (
            this.event.subscription &&
            this.event.subscription.participation &&
            this.event.subscription.participation.experience_id !== null
        ) {
            this.experience = this.event.experiences.find(
                (experience: ExperienceModel) => experience.id === this.event.subscription?.participation.experience_id,
            )!;

            this.participantForm.get('experience_id')?.setValue(this.event.subscription.participation.experience_id);
        }
    }

    populateAttachments(participant: any): void {
        if (participant.attachments.length !== 0) {
            participant.attachments.forEach((attachment: any) => {
                const fieldName = this.participantAttachmentTypeById[attachment.type_id].tag.toLowerCase();

                this.participantForm.get(fieldName + '.disclaimer')?.patchValue({
                    documentId: attachment.id,
                    documentName: attachment.name,
                    documentSize: attachment.size,
                    documentType: attachment.type_id,
                });
            });
        }
    }

    configureAttachments(): void {
        this.userCommercialStructure.attachments.forEach((attachment: any) => {
            const attachmentTypeName = this.eventAttachmentTypeById[attachment.type_id].tag;
            const allowedAttachments = this.getTravelCompanionshipStatusAttachments();

            // set all available attachments as mandatory
            if (this.participantForm.get(attachmentTypeName.toLowerCase())) {
                this.participantForm.get(attachmentTypeName.toLowerCase())?.patchValue({
                    is_mandatory: allowedAttachments.includes(attachmentTypeName),
                });
            }
        });
    }

    getTravelCompanionshipStatusAttachments(): string[] {
        const statusId = this.participantForm.get('companionship_status_id')?.value;
        const attachmentsList = [];

        if (statusId !== null) {
            switch (this.travelCompanionshipStatusById[statusId].tag) {
                case Constants.EVENT_SUBSCRIPTION_SUBSCRIBER_COMPANIONSHIP_STATUSES_WITH_COMPANION_OR_COLLABORATOR:
                    attachmentsList.push(Constants.EVENT_ATTACHMENT_TYPES_WINNING_DECLARATION);
                    break;
                case Constants.EVENT_SUBSCRIPTION_SUBSCRIBER_COMPANIONSHIP_STATUSES_ALONE:
                    attachmentsList.push(
                        Constants.EVENT_ATTACHMENT_TYPES_PARTIAL_RENOUNCE_DECLARATION,
                        Constants.EVENT_ATTACHMENT_TYPES_PARTIAL_NON_PARTICIPATION_DECLARATION,
                    );
                    break;
                case Constants.EVENT_SUBSCRIPTION_SUBSCRIBER_COMPANIONSHIP_STATUSES_NOT_PARTICIPATING:
                    attachmentsList.push(
                        Constants.EVENT_ATTACHMENT_TYPES_TOTAL_RENOUNCE_DECLARATION,
                        Constants.EVENT_ATTACHMENT_TYPES_NON_PARTICIPATION_DECLARATION,
                    );
                    break;
            }
        }

        return attachmentsList;
    }

    hasTravelEventAttachment(attachmentType: string): boolean {
        return this.participantForm.get(`${attachmentType.toLowerCase()}.is_mandatory`)?.value === true;
    }

    getTypeIdFromRoleId(): number | null {
        if (this.userData.role_id) {
            switch (this.roleById[this.userData.role_id].tag) {
                case Constants.USER_ROLES_AZ_AGENT:
                    return this.participantTypeByTag[Constants.EVENT_EXPERIENCE_SUBSCRIPTION_PARTICIPANT_TYPES_AZ_AGENT].id;
                case Constants.USER_ROLES_AZB_FINANCIAL_CONSULTANT:
                    return this.participantTypeByTag[Constants.EVENT_EXPERIENCE_SUBSCRIPTION_PARTICIPANT_TYPES_AZB_FINANCIAL_CONSULTANT].id;
            }
        }

        return null;
    }

    setExperience(): void {
        this.experience = this.event.experiences.find(
            (experience: ExperienceModel) => experience.id === this.participantForm.value.experience_id,
        )!;

        this._getAdditionalInformation();
    }

    getAgencies(): void {
        this.experienceService.getAgencies(this.userData.id).subscribe((agencies: AgencyModel[]) => {
            this.agencies = agencies;

            this.setAgencyRequired();
        });
    }

    setAgencyRequired(): void {
        if (this.agencies.length > 1) {
            this.participantForm.get('agency_id')?.setValidators(Validators.required);
            this.participantForm.get('agency_id')?.updateValueAndValidity();
        }
        if (this.agencies && this.agencies.length === 1) {
            this.participantForm.get('agency_id')?.setValue(this.agencies[0].id);
        }
    }

    onSetCurrentStepClick(step: number | undefined = undefined): void {
        if (step === undefined) {
            this.closeModal();
            return;
        }
        this.currentStep = step;
        this.changeDetectorRef.detectChanges();
    }

    closeModal(): void {
        this.toggleModal(this.dialog);
    }

    nextStepAfterParticipationAnswer(): void {
        if (this.participantForm.value.is_participating || (this.isTravelEvent && this.isAgent && !this.declinedWithoutAttachments())) {
            this.onSetCurrentStepClick(
                this.event.experiences.length > 1 && !this.isFromExperienceCard
                    ? Constants.EXPERIENCE_REGISTRATION_MODAL_STEP_EXPERIENCES
                    : (this.isTravelEvent || this.agencies.length > 1) && this.isAgent
                      ? Constants.EXPERIENCE_REGISTRATION_MODAL_STEP_PRIVACY
                      : Constants.EXPERIENCE_REGISTRATION_MODAL_STEP_PRIVACY_INTERNAL,
            );
        } else {
            this.disableContinueButton = true;
            this.onExperienceRegistrationClick();
        }
    }

    declinedWithoutAttachments(): boolean {
        const companionshipStatusId = this.participantForm.get('companionship_status_id')?.value;
        const csAttachmentIds = this.userCommercialStructure.attachments.map((attachment: any) => attachment.type_id);

        return (
            this.travelCompanionshipStatusById[companionshipStatusId].tag ===
                Constants.EVENT_SUBSCRIPTION_SUBSCRIBER_COMPANIONSHIP_STATUSES_NOT_PARTICIPATING &&
            !csAttachmentIds.includes(this.eventAttachmentTypeByTag[Constants.EVENT_ATTACHMENT_TYPES_TOTAL_RENOUNCE_DECLARATION].id) &&
            !csAttachmentIds.includes(this.eventAttachmentTypeByTag[Constants.EVENT_ATTACHMENT_TYPES_NON_PARTICIPATION_DECLARATION].id)
        );
    }

    async onExperienceRegistrationClick(): Promise<void> {
        if (this.participantForm.value.is_participating && this.participantForm.invalid && !this.isNetworkEvent) {
            this.updating = false;
            return;
        }
        const questions = await this.createAdditionalInformationData();
        let data;

        data = {
            additional_info: questions.length ? { questions: questions } : null,
            is_base_privacy_signed: this.participantForm.get('privacy.is_base_privacy_signed')?.value,
            is_health_privacy_signed: this.participantForm.get('privacy.is_health_privacy_signed')?.value,
            is_image_privacy_signed: this.participantForm.get('privacy.is_image_privacy_signed')?.value || false,
            az_agency_id: this.participantForm.value.agency_id,
        };

        if (this.isNetworkEvent || this.isAgent) {
            if (this.participantForm.value.is_participating) {
                data = {
                    ...data,
                    [this.isNetworkEvent ? 'is_participating' : 'is_participant']: this.participantForm.value.is_participating,
                    event_experience_id: this.participantForm.value.experience_id,
                };
            } else {
                data = {
                    [this.isNetworkEvent ? 'is_participating' : 'is_participant']: this.participantForm.value.is_participating,
                };
            }
            if (this.isTravelEvent && this.isAgent) {
                data = {
                    ...data,
                    companionship_status_id: this.participantForm.value.companionship_status_id,
                    attachments: this.createAttachmentData(),
                };
            }
        }

        this.updating = true;
        if (this.isAgent) {
            if (this.isNetworkEvent) {
                this.createUserSubscription(data);
            } else {
                if (!this.event.subscription) {
                    this.createAgentSubscription(data);
                } else {
                    this.editAgentSubscription(data);
                }
            }
        } else {
            data = {
                ...data,
                event_experience_id: this.participantForm.value.experience_id,
            };
            this.createUserSubscription(data);
        }
    }

    createAgentSubscription(data: any): void {
        this.experienceService.sendAgentSubscription(data, this.participantForm.value.experience_id).subscribe(
            (response: EventModel) => {
                this.updating = false;

                this.eventService.updateEvent(response);
                this.closeModal();
            },
            (error: HttpErrorResponse) => {
                this.updating = false;
                this.errorService.openModalError(error, 'Errore nella registrazione ad una experience');
            },
        );
    }

    createUserSubscription(data: any): void {
        if (this.event.subscription && this.event.subscription.participation) {
            this.experienceService.sendUserSubscription(data, this.event.subscription.participation.id).subscribe(
                (response: EventModel) => {
                    this.updating = false;

                    this.eventService.updateEvent(response);
                    this.closeModal();
                },
                (error: HttpErrorResponse) => {
                    this.updating = false;
                    this.errorService.openModalError(error, 'Errore nella registrazione ad una experience');
                },
            );
        }
    }

    editAgentSubscription(data: any): void {
        this.experienceService.editSubscription(data, this.event.subscription!.id).subscribe(
            (response: EventModel) => {
                this.updating = false;

                this.eventService.updateEvent(response);
                this.closeModal();
            },
            (error: HttpErrorResponse) => {
                this.updating = false;
                this.errorService.openModalError(error, 'Errore nella registrazione ad una experience');
            },
        );
    }

    onOpenFileClick(): void {
        let serviceCall;
        let privacy: AttachmentModel | undefined;

        if (this.experience?.commercial_structures[0].is_disclaimer_1_from_event) {
            const commercialStructureId =
                this.isEmployee &&
                this.commercialStructureById[this.experience?.commercial_structures[0].id].tag === Constants.COMMERCIAL_STRUCTURES_AZB
                    ? this.experience?.commercial_structures[0].id
                    : 0;
            privacy = this.event.commercial_structures
                .find((commercialStructure: any) => commercialStructure.id === commercialStructureId)
                ?.attachments.find(
                    (attachment: AttachmentModel) =>
                        this.eventAttachmentById[attachment.type_id].tag === Constants.EVENT_ATTACHMENT_TYPES_DISCLAIMER,
                );
            serviceCall = this.eventService;
        } else {
            privacy = this.experience?.commercial_structures[0].attachments.find(
                (attachment: AttachmentModel) =>
                    this.experienceAttachmentById[attachment.type_id].tag === Constants.EVENT_EXPERIENCE_ATTACHMENT_TYPES_DISCLAIMER_1,
            );
            serviceCall = this.experienceService;
        }

        if (privacy !== undefined) {
            serviceCall.getAttachment(privacy.id).subscribe(
                (response: AttachmentModel) => {
                    const linkSource = `data:application/octet-stream;base64,${response.filestream}`;
                    const downloadLink = document.createElement('a');

                    downloadLink.href = linkSource;
                    downloadLink.download = privacy?.name || 'privacy.pdf';
                    downloadLink.click();
                    downloadLink.remove();
                },
                (error: HttpErrorResponse) => this.errorService.openModalError(error, "Errore nel caricamento dell'allegato"),
            );
        }
    }

    getIsParticipating(participant: any): boolean | null {
        switch (this.participantInvitationStatusById[participant.invitation_status_id].tag) {
            case Constants.EVENT_EXPERIENCE_SUBSCRIPTION_PARTICIPANT_INVITATION_STATUSES_ACCEPTED:
                return true;
            case Constants.EVENT_EXPERIENCE_SUBSCRIPTION_PARTICIPANT_INVITATION_STATUSES_REFUSED:
                return false;
            default:
                return null;
        }
    }

    onDownloadAttachmentTemplate(attachmentType: string): void {
        const requestedAttachment = this.userCommercialStructure.attachments.find(
            (attachment: any) => attachment.type_id === this.eventAttachmentTypeByTag[attachmentType].id,
        );

        if (requestedAttachment) {
            this.eventService.getAttachment(requestedAttachment.id).subscribe({
                next: (response: any) => this.downloadAttachment(response.filestream, requestedAttachment.name),
                error: (error: HttpErrorResponse) => this.errorService.openModalError(error, 'Error fetching Attachment'),
            });
        }
    }
}
