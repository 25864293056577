import { Pipe, PipeTransform } from '@angular/core';

import dayjs from 'dayjs';

dayjs.locale('it');

@Pipe({
    name: 'dateParser',
    standalone: false,
})
export class DateParserPipe implements PipeTransform {
    transform(date: any): any {
        if (date === null || !date) {
            return date;
        } else {
            return dayjs(date);
        }
    }
}
