import { __decorate, __param } from 'tslib';
import * as i0 from '@angular/core';
import { Optional, Inject, NgModule } from '@angular/core';
import { NxDateAdapter, NX_DATE_LOCALE, NX_DATE_LOCALE_PROVIDER, NX_DATE_FORMATS } from '@aposin/ng-aquila/datefield';
import { pad } from '@aposin/ng-aquila/utils';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localeData from 'dayjs/plugin/localeData';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import utc from 'dayjs/plugin/utc';
import supportedLocales from 'dayjs/locale.json';

/**
 * This function turns a well-formatted locale into one that is known to dayjs.
 * To figure out what is known to dayjs this function makes use of the file `locale.json`
 * that is part of any dayjs relase (see https://day.js.org/docs/en/i18n/i18n).
 * To match the locale it follows the "lookup" logic specified in the RFC for
 * "Matching of Language Tags" (see https://www.rfc-editor.org/info/rfc4647).
 * @example en-GB -> en-gb, en-US -> en, de-DE -> de, de-CH -> de-ch
 * @param localeId A "language-range" following the BCP 47 standard.
 * @returns A "language-range" that is supported by dayjs.
 */
function convertToDayjsLocale(localeId) {
  let dayjsLocale = supportedLocales.find(locale => locale.key === localeId.toLowerCase());
  if (!dayjsLocale && localeId.includes('-')) {
    let localeParts = localeId.toLowerCase().split('-');
    while (localeParts.length > 0) {
      dayjsLocale = supportedLocales.find(locale => locale.key === localeParts.join('-'));
      if (dayjsLocale) {
        console.warn(`The locale '${localeId}' is not known to dayjs. Using closest match '${dayjsLocale.key}' instead.`);
        break;
      }
      localeParts = localeParts.slice(0, localeParts.length - 1);
    }
  }
  if (!dayjsLocale) {
    console.warn(`The locale '${localeId}' is not known to dayjs. Using the default 'en'.`);
    dayjsLocale = {
      key: 'en',
      name: ''
    };
  }
  return dayjsLocale.key;
}
dayjs.extend(localeData);
dayjs.extend(customParseFormat);
dayjs.extend(localizedFormat);
dayjs.extend(utc);
const ISO_STRING_FORMAT = 'YYYY-MM-DD';
const ISO_REGEX = /^(\d{4})(-?)(1[0-2]|0[1-9])\2(3[01]|0[1-9]|[12]\d)$/;
/** Creates an array and fills it with values. */
function range(length, valueFunction) {
  const valuesArray = Array(length);
  for (let i = 0; i < length; i++) {
    valuesArray[i] = valueFunction(i);
  }
  return valuesArray;
}
/** @docs-private */
let NxIsoDateAdapter = class NxIsoDateAdapter extends NxDateAdapter {
  constructor(dateLocale) {
    super();
    this.setLocale(dateLocale || dayjs.locale());
  }
  toIso8601(date) {
    return dayjs.utc(date).format();
  }
  deserialize(value) {
    let date;
    // TODO should we accept that or only take strings?
    if (value instanceof Date) {
      date = dayjs(value).locale(this._dayjsLocale);
    }
    if (typeof value === 'string') {
      if (!value) {
        return null;
      }
      date = dayjs(value).locale(this._dayjsLocale);
    }
    if (date && this.isValid(date.toString())) {
      return dayjs(date).locale(this._dayjsLocale).format(ISO_STRING_FORMAT);
    }
    return super.deserialize(value);
  }
  format(date, displayFormat) {
    return this._createDayjs(date).format(displayFormat);
  }
  /**
   * If the given formats include a localized format we have to map
   * it manually to a dayjs format as dayjs recognizes locale
   * format only for formatting not for parsing
   * see https://github.com/iamkun/dayjs/issues/694#issuecomment-543209946.
   */
  normalizeFormat(format) {
    let availableLocalFormats = dayjs.Ls[this._dayjsLocale]?.formats;
    if (!availableLocalFormats) {
      availableLocalFormats = dayjs.Ls[dayjs.locale()]?.formats;
      console.warn(`NxIsoDateAdapter: The used locale "${this._dayjsLocale}" is not available in this day.js instance. Please make sure the locale is imported.`);
    }
    let normalizedFormat = format;
    if (!Array.isArray(normalizedFormat)) {
      normalizedFormat = [normalizedFormat];
    }
    normalizedFormat = normalizedFormat.map(formatString => {
      if (Object.keys(availableLocalFormats).includes(formatString)) {
        return availableLocalFormats[formatString];
      }
      return formatString;
    });
    return normalizedFormat;
  }
  parse(value, format, strict) {
    let obj;
    const normalizedFormats = this.normalizeFormat(format);
    if (value && typeof value === 'string') {
      if (strict) {
        obj = dayjs(value, normalizedFormats, this._dayjsLocale, true);
      } else {
        // The non strict parsing of day.js is rather strict when it comes to separators.
        // For example, this format: YYYY-MM-DD still requires the user to type in the -
        // To get a little closer to the behavior of momentjs, the following code extends
        // the list of given formats with versions were all the separators were removed
        const formatsWithoutSeparators = [...normalizedFormats].map(normalizedformat => normalizedformat.replace(/\W/g, ''));
        obj = dayjs(value, [...formatsWithoutSeparators, ...normalizedFormats], this._dayjsLocale, false);
      }
    }
    if (!obj?.isValid()) {
      return '';
    }
    return obj ? obj.format(ISO_STRING_FORMAT) : '';
  }
  isValid(date) {
    return dayjs(date).isValid();
  }
  clone(date) {
    return date;
  }
  isDateInstance(obj) {
    return obj === '' || typeof obj === 'string' && ISO_REGEX.test(obj);
  }
  invalid() {
    return '';
  }
  getYear(date) {
    return this._createDayjs(date).year();
  }
  getMonth(date) {
    return this._createDayjs(date).month();
  }
  getDate(date) {
    return this._createDayjs(date).date();
  }
  getYearName(date) {
    return this._createDayjs(date).format('YYYY');
  }
  createDate(year, month, date) {
    // Check for invalid month and date (except upper bound on date which we have to check after
    // creating the Date).
    if (month < 0 || month > 11) {
      throw Error(`Invalid month index "${month}". Month index has to be between 0 and 11.`);
    }
    if (date < 1) {
      throw Error(`Invalid date "${date}". Date has to be greater than 0.`);
    }
    // dayjs adds overflows up instead of creating invalid dates so we have to check that here
    const obj = this._createDayjs(this._createString(year, month + 1, date));
    // Check that the date wasn't above the upper bound for the month, causing the month to overflow
    if (obj.month() !== month || obj.year() !== year) {
      throw Error(`Invalid date "${date}" for month with index "${month}".`);
    }
    return obj.format(ISO_STRING_FORMAT);
  }
  getNumDaysInMonth(date) {
    return this._createDayjs(date).daysInMonth();
  }
  getDateNames() {
    return this._localeData.dates;
  }
  getDayOfWeek(date) {
    return this._createDayjs(date).day();
  }
  getFirstDayOfWeek() {
    return this._localeData.firstDayOfWeek;
  }
  getMonthNames(style) {
    return style === 'long' ? this._localeData.longMonths : this._localeData.shortMonths;
  }
  today() {
    return dayjs().format(ISO_STRING_FORMAT);
  }
  addCalendarMonths(date, months) {
    return this._createDayjs(date).add(months, 'M').format(ISO_STRING_FORMAT);
  }
  addCalendarYears(date, years) {
    return this._createDayjs(date).add(years, 'y').format(ISO_STRING_FORMAT);
  }
  addCalendarDays(date, days) {
    return this._createDayjs(date).add(days, 'd').format(ISO_STRING_FORMAT);
  }
  getDayOfWeekNames(style) {
    if (style === 'long') {
      return this._localeData.longDaysOfWeek;
    }
    if (style === 'short') {
      return this._localeData.shortDaysOfWeek;
    }
    return this._localeData.narrowDaysOfWeek;
  }
  setLocale(locale) {
    this._dayjsLocale = convertToDayjsLocale(locale);
    const data = dayjs().locale(this._dayjsLocale).localeData();
    this._localeData = {
      firstDayOfWeek: data.firstDayOfWeek(),
      longMonths: data.months(),
      shortMonths: data.monthsShort(),
      dates: range(31, i => this._createDayjs(this.createDate(2017, 0, i + 1)).format('D')),
      longDaysOfWeek: data.weekdays(),
      shortDaysOfWeek: data.weekdaysShort(),
      narrowDaysOfWeek: data.weekdaysMin()
    };
    super.setLocale(locale);
  }
  _createDayjs(value) {
    return dayjs.utc(value).locale(this._dayjsLocale);
  }
  _createString(year, month, date) {
    return `${year}-${pad(month.toString())}-${pad(date.toString())}`;
  }
};
NxIsoDateAdapter = __decorate([__param(0, Optional()), __param(0, Inject(NX_DATE_LOCALE))], NxIsoDateAdapter);
const NX_ISO_DATE_FORMATS = {
  parse: {
    dateInput: 'L'
  },
  display: {
    dateInput: 'L',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  }
};
class IsoDateModule {
  static {
    this.ɵfac = function IsoDateModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || IsoDateModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: IsoDateModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      providers: [NX_DATE_LOCALE_PROVIDER, {
        provide: NxDateAdapter,
        useClass: NxIsoDateAdapter,
        deps: [NX_DATE_LOCALE]
      }]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IsoDateModule, [{
    type: NgModule,
    args: [{
      providers: [NX_DATE_LOCALE_PROVIDER, {
        provide: NxDateAdapter,
        useClass: NxIsoDateAdapter,
        deps: [NX_DATE_LOCALE]
      }]
    }]
  }], null, null);
})();
class NxIsoDateModule {
  static {
    this.ɵfac = function NxIsoDateModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || NxIsoDateModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: NxIsoDateModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      providers: [{
        provide: NX_DATE_FORMATS,
        useValue: NX_ISO_DATE_FORMATS
      }],
      imports: [IsoDateModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NxIsoDateModule, [{
    type: NgModule,
    args: [{
      imports: [IsoDateModule],
      providers: [{
        provide: NX_DATE_FORMATS,
        useValue: NX_ISO_DATE_FORMATS
      }]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { IsoDateModule, NX_ISO_DATE_FORMATS, NxIsoDateAdapter, NxIsoDateModule };
