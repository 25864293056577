import { Component, Inject, OnInit } from '@angular/core';
import { NX_MODAL_DATA, NxModalRef } from '@aposin/ng-aquila/modal';
import { UtilsService } from '@app/core/services/base/utils.service';
import { LoggedUserModel } from '@app/shared/models/logged-user.model';

@Component({
    selector: 'azw-confirmation-modal',
    templateUrl: './confirmation-modal.component.html',
    styleUrls: ['./confirmation-modal.component.scss'],
    standalone: false,
})
export class ConfirmationModalComponent implements OnInit {
    public event: any;
    public user!: LoggedUserModel;
    public dateTitle = '';

    constructor(
        private utilsService: UtilsService,
        private dialog: NxModalRef<ConfirmationModalComponent>,
        @Inject(NX_MODAL_DATA) public data: any,
    ) {
        if (data && Object.keys(data).length !== 0) {
            this.event = data.event !== undefined ? data.event : null;
            this.user = data.user !== undefined ? data.user : null;
        }
    }

    ngOnInit(): void {
        this.setModalTitle();
    }

    setModalTitle(): void {
        this.dateTitle = this.utilsService.getModalDateTitle(this.event);
    }

    toggleModal(confirm = false): void {
        confirm ? this.dialog.close(confirm) : this.dialog.close();
    }
}
