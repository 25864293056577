import { removeClasses, mapClassNames, appendClasses } from '@aposin/ng-aquila/utils';
const BREAKPOINT_MOBILE = '(max-width: 703px)';
const BREAKPOINT_TABLET = '(min-width: 704px) and (max-width: 991px)';
const BREAKPOINT_DESKTOP = '(min-width: 992px) and (max-width: 1279px)';
const BREAKPOINT_XLARGE = '(min-width: 1280px) and (max-width: 1471px)';
const BREAKPOINT_2XLARGE = '(min-width: 1472px) and (max-width: 1759px)';
const BREAKPOINT_3XLARGE = '(min-width: 1760px)';
class MappedStyles {
  set classNames(value) {
    if (this._classNames === value) {
      return;
    }
    removeClasses(this._renderer, this._elementRef, this._classNamesSanitized);
    this._classNamesSanitized = mapClassNames(value, this.baseClasses, this.mapping);
    this._classNames = value;
    appendClasses(this._renderer, this._elementRef, this._classNamesSanitized);
  }
  get classNames() {
    return this._classNames;
  }
  constructor(mapping, _elementRef, _renderer, baseClasses = []) {
    this.mapping = mapping;
    this._elementRef = _elementRef;
    this._renderer = _renderer;
    this.baseClasses = baseClasses;
    this.classNames = ''; // invoke setter
  }
}

/**
 * Generated bundle index. Do not edit.
 */

export { BREAKPOINT_2XLARGE, BREAKPOINT_3XLARGE, BREAKPOINT_DESKTOP, BREAKPOINT_MOBILE, BREAKPOINT_TABLET, BREAKPOINT_XLARGE, MappedStyles };
