import { Inject, Injectable, InjectionToken, PLATFORM_ID } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';

import { Constants } from '@app/core/constants/constants';
import { EventModel } from '@app/shared/models/event.model';

import { environment } from '@env/environment';

import dayjs from 'dayjs';
import * as ics from 'ics';
import { DateArray, EventAttributes } from 'ics';

@Injectable({
    providedIn: 'root',
})
export class UtilsService {
    constructor(
        private router: Router,
        @Inject(PLATFORM_ID) private platformId: InjectionToken<Object>,
    ) {}

    //
    // ─── SERVICE MESSAGE COMMANDS ───────────────────────────────────────────────────
    //

    arrayToObject(array: any, keyField: string): any {
        return array.reduce((obj: any, item: any) => {
            obj[item[keyField]] = item;
            return obj;
        }, {});
    }

    objectToArray(object: any): any[] {
        return Object.keys(object).map((key: string) => object[key]);
    }

    objectToObjectWithNewKey(object: any, keyField: string): Object {
        return this.arrayToObject(
            Object.keys(object).map((key: string) => object[key]),
            keyField,
        );
    }

    sortArray(array: any[], propertyToSort: string): any[] {
        return array.sort((a: any, b: any) => {
            if (a[propertyToSort] < b[propertyToSort]) {
                return -1;
            } else if (a[propertyToSort] > b[propertyToSort]) {
                return 1;
            }
            return 0;
        });
    }

    sortReverseArray(array: any, propertyToSort: string): any[] {
        return array.sort((a: any, b: any) => {
            if (a[propertyToSort] > b[propertyToSort]) {
                return -1;
            } else if (a[propertyToSort] < b[propertyToSort]) {
                return 1;
            }
            return 0;
        });
    }

    removeUndefinedFromObject(object: any): any {
        Object.keys(object).forEach((key: string) => (object[key] === undefined || object[key] === '' ? delete object[key] : ''));
        return object;
    }

    removeUndefinedAndNullFromObject(object: any): any {
        // tslint:disable-next-line:max-line-length
        Object.keys(object).forEach((key: string) =>
            object[key] === undefined || object[key] === null || object[key] === '' ? delete object[key] : '',
        );
        return object;
    }

    base64ToArrayBuffer(base64: any): Uint8Array {
        const binaryString = window.atob(base64);
        const binaryLen = binaryString.length;
        const bytes = new Uint8Array(binaryLen);
        for (let i = 0; i < binaryLen; i++) {
            const ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
        }
        return bytes;
    }

    saveByteArray(name: string, mimeType: string, base64: string): void {
        const byte = this.base64ToArrayBuffer(base64);
        if (byte) {
            const blob = new Blob([byte], { type: mimeType });
            // this.fileSaverService.save(blob, name);
        }
    }

    /**
     * Marks all controls in a form group as touched
     * @param formGroup - The form group to touch
     */
    markFormGroupTouched(formGroup: UntypedFormGroup): void {
        (Object as any).values(formGroup.controls).forEach((control: UntypedFormControl | any) => {
            control.markAsTouched();

            if (control.controls) {
                this.markFormGroupTouched(control);
            }
        });
    }

    isPrivateHostname(): boolean {
        const privateUrls = [
            'portaleagenzie.in.azi.allianzit',
            'portaleagenzie.te.azi.allianzit',
            'portaleagenzie.pp.azi.allianz.it',
            'portaleagenzie.allianz.it',
            'consulente.allianzbank.it',
            'consulentepp.allianzbank.it',
            // 'localhost'
        ];

        return privateUrls.includes(window.location.hostname);
    }

    isConsultantHost(): boolean {
        const consultantHosts = ['consulente.allianzbank.it', 'consulentepp.allianzbank.it'];

        return consultantHosts.includes(window.location.hostname);
    }

    checkIfDevelopmentEnvironment(): boolean {
        switch (environment.env) {
            case Constants.ENVIRONMENT_LOCAL:
            case Constants.ENVIRONMENT_DEV:
            case Constants.ENVIRONMENT_QA:
            case Constants.ENVIRONMENT_STAGING:
            case Constants.ENVIRONMENT_CRP_DEV:
            case Constants.ENVIRONMENT_CRP_TEST:
                return true;
            default:
                return false;
        }
    }

    //
    // ─── PWA UTILS - METHODS ────────────────────────────────────────────────────────
    //

    isInStandaloneMode(): boolean {
        return window.matchMedia('(display-mode: standalone)').matches;
    }

    //
    // ─── METHODS ────────────────────────────────────────────────────────────────────
    //

    getCurrentPath(): string {
        return this.router.url.substr(1);
    }

    getLocationFullName(location: any = null): string {
        let clearAddress = '';
        if (location) {
            const street = location.street || location.addressStreet;
            const house_number = location.house_number || location.addressHouseNumber;
            const zip_code = location.zip_code || location.addressZipCode;
            const city_long_name = location.city_long_name || location.addressCity;
            const province_short_name = location.province_short_name || location.addressProvince;
            const country_short_name = location.country_short_name || location.addressCountry;

            clearAddress += street ? street : '';
            clearAddress += house_number ? ' ' + house_number : '';
            clearAddress += zip_code ? ', ' + zip_code : '';
            clearAddress += city_long_name ? ', ' + city_long_name : '';
            clearAddress += province_short_name ? ' ' + province_short_name : '';
            clearAddress += country_short_name ? ', ' + country_short_name : '';
        }

        return clearAddress;
    }

    createICS(event: EventModel): void {
        if (event) {
            let eventAttributes: EventAttributes;
            const eventStartDate = this.momentToDateArray(dayjs(event.start_date));
            const eventEndDate = this.momentToDateArray(dayjs(event.end_date));
            const fileName = `${event.name.replace(/[^a-zA-Z0-9- ]/g, '')} - ${dayjs(event.start_date).format('DD MM YYYY')}`;

            // TODO: duration not required with start_date and end_date
            // const eventDuration = moment.utc(moment.duration(moment(this.event.end_date).diff(moment(this.event.start_date))).asMilliseconds()).toObject();
            eventAttributes = {
                start: eventStartDate,
                end: eventEndDate,
                // duration: {hours: eventDuration.hours, minutes: eventDuration.minutes},
                title: event.name,
                description: event.description + '\n' + window.location.href,
                url: window.location.href,
            };

            if (event.location && this.getLocationFullName(event.location)) {
                eventAttributes = {
                    ...eventAttributes,
                    location: (event.location.name ? event.location.name + ' - ' : '') + this.getLocationFullName(event.location),
                };
                if (event.location.latitude && event.location.longitude) {
                    eventAttributes = {
                        ...eventAttributes,
                        geo: { lat: event.location.latitude, lon: event.location.longitude },
                    };
                }
            }
            ics.createEvent(eventAttributes, (error: any, value: string) => {
                if (error) {
                    console.log(error);
                    return;
                }

                this.createAndDownloadFile(value, fileName, 'text/calendar');
            });
        }
    }

    createAndDownloadFile(data: any, filename: string, type: string): void {
        const file = new Blob([data], { type: type });
        if ((window.navigator as any)['msSaveOrOpenBlob']) {
            (window.navigator as any)['msSaveOrOpenBlob'](file, filename);
        } else {
            const a = document.createElement('a'),
                url = URL.createObjectURL(file);
            a.href = url;
            a.download = filename;
            document.body.appendChild(a);
            a.click();
            setTimeout(() => {
                document.body.removeChild(a);
                window.URL.revokeObjectURL(url);
            }, 0);
        }
    }

    momentToDateArray(date: dayjs.Dayjs): DateArray {
        return [
            parseInt(date.format('YYYY')),
            parseInt(date.format('MM')),
            parseInt(date.format('DD')),
            parseInt(date.format('HH')),
            parseInt(date.format('mm')),
        ];
    }

    openFile(filename: string): void {
        window.open(`assets/documents/${filename}`, '_blank');
    }

    openPrivacyFile(): void {
        window.open('https://www.allianz.it/content/dam/onemarketing/azit/allianz-it/area-personale/doc/PrivacyIndustriale.pdf', '_blank');
    }

    reloadPageWithoutParameters(): void {
        window.location.replace(location.protocol + '//' + location.host + location.pathname);
    }

    lpad(s: any, len: any, chr: any): any {
        const L = len - s.length;
        const C = chr || ' ';
        if (L <= 0) {
            return s;
        }
        return new Array(L + 1).join(C) + s;
    }

    getModalDateTitle(event: any): string {
        const moreDays = dayjs(event.start_date).isBefore(dayjs(event.end_date), 'day');
        let title = '';

        if (event.start_date) {
            if (moreDays) {
                title = 'da ';
            }
            title += dayjs(event.start_date).format('DD/MM/YY');
        }
        if (moreDays) {
            title += ' a ' + dayjs(event.end_date).format('DD/MM/YY');
        }

        return title;
    }
}
