import { Component, Input } from '@angular/core';

@Component({
    selector: 'azd-empty-results',
    templateUrl: './empty-results.component.html',
    styleUrls: ['./empty-results.component.scss'],
    standalone: false,
})
export class EmptyResultsComponent {
    @Input() message!: string;

    constructor() {}
}
