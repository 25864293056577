import * as i0 from '@angular/core';
import { Component, ChangeDetectionStrategy, Input, Directive, NgModule } from '@angular/core';
const _c0 = ["nxFigure", ""];
const _c1 = ["*"];
const DEFAULT_SIZE = 'auto';
class NxFigureComponent {
  constructor() {
    /** @docs-private */
    this.rounded = false;
    /** @docs-private */
    this.size = DEFAULT_SIZE;
  }
  /**
   * Sets the type of the visual appearance of the image.
   * The default value  is 'auto'.
   */
  set classNames(value) {
    if (this._classNames === value) {
      return;
    }
    this._classNames = value; // TODO properly coerce input value
    // TODO kick null safe-guards after setter value or any calling input values are properly coerced as string
    const sizeRegex = /^(auto|1by1|1dot8by1|1dot2by1|1by1dot1|2dot6by1)$/;
    const [size = null] = this._classNames?.match(sizeRegex) || [DEFAULT_SIZE];
    this.size = size;
    this.rounded = !!this._classNames?.match(/rounded/);
  }
  get classNames() {
    return this._classNames;
  }
  static {
    this.ɵfac = function NxFigureComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || NxFigureComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: NxFigureComponent,
      selectors: [["figure", "nxFigure", ""]],
      hostVars: 16,
      hostBindings: function NxFigureComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵclassProp("nx-image", true)("nx-image--auto", ctx.size === "auto" && !ctx.rounded)("nx-image--1by1", ctx.size === "1by1")("nx-image--1dot8by1", ctx.size === "1dot8by1")("nx-image--1dot2by1", ctx.size === "1dot2by1")("nx-image--1by1dot1", ctx.size === "1by1dot1")("nx-image--2dot6by1", ctx.size === "2dot6by1")("nx-image--rounded", ctx.rounded);
        }
      },
      inputs: {
        classNames: [0, "nxFigure", "classNames"]
      },
      attrs: _c0,
      ngContentSelectors: _c1,
      decls: 1,
      vars: 0,
      template: function NxFigureComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      styles: ["[_nghost-%COMP%]{display:block;position:relative;line-height:0}[_nghost-%COMP%]     img{display:block;font-family:\"object-fit: cover;\";object-fit:cover;height:100%;width:100%}.nx-image--auto[_nghost-%COMP%]     img{height:auto}[_nghost-%COMP%]:not(.nx-image--auto)     img{position:absolute;top:0;left:0}[dir=rtl]   [_nghost-%COMP%]:not(.nx-image--auto)     img{right:0;left:auto}.nx-image--rounded[_nghost-%COMP%]{padding-top:100%}.nx-image--rounded[_nghost-%COMP%]     img{border-radius:50%;inset:0;position:absolute}.nx-image--1by1[_nghost-%COMP%]{padding-top:100%}.nx-image--1dot8by1[_nghost-%COMP%]{padding-top:55.5555555556%}.nx-image--1dot2by1[_nghost-%COMP%]{padding-top:83.3333333333%}.nx-image--1by1dot1[_nghost-%COMP%]{padding-top:110%}.nx-image--2dot6by1[_nghost-%COMP%]{padding-top:38.4615384615%}.nx-image--1by1dot4[_nghost-%COMP%]{padding-top:140%}"],
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NxFigureComponent, [{
    type: Component,
    args: [{
      selector: 'figure[nxFigure]',
      template: '<ng-content></ng-content>',
      changeDetection: ChangeDetectionStrategy.OnPush,
      host: {
        '[class.nx-image]': 'true',
        '[class.nx-image--auto]': 'size === "auto" && !rounded',
        '[class.nx-image--1by1]': 'size === "1by1"',
        '[class.nx-image--1dot8by1]': 'size === "1dot8by1"',
        '[class.nx-image--1dot2by1]': 'size === "1dot2by1"',
        '[class.nx-image--1by1dot1]': 'size === "1by1dot1"',
        '[class.nx-image--2dot6by1]': 'size === "2dot6by1"',
        '[class.nx-image--rounded]': 'rounded'
      },
      standalone: true,
      styles: [":host{display:block;position:relative;line-height:0}:host ::ng-deep img{display:block;font-family:\"object-fit: cover;\";object-fit:cover;height:100%;width:100%}:host(.nx-image--auto) ::ng-deep img{height:auto}:host(:not(.nx-image--auto)) ::ng-deep img{position:absolute;top:0;left:0}[dir=rtl] :host(:not(.nx-image--auto)) ::ng-deep img{right:0;left:auto}:host(.nx-image--rounded){padding-top:100%}:host(.nx-image--rounded) ::ng-deep img{border-radius:50%;inset:0;position:absolute}:host(.nx-image--1by1){padding-top:100%}:host(.nx-image--1dot8by1){padding-top:55.5555555556%}:host(.nx-image--1dot2by1){padding-top:83.3333333333%}:host(.nx-image--1by1dot1){padding-top:110%}:host(.nx-image--2dot6by1){padding-top:38.4615384615%}:host(.nx-image--1by1dot4){padding-top:140%}\n"]
    }]
  }], null, {
    classNames: [{
      type: Input,
      args: ['nxFigure']
    }]
  });
})();
class NxImageDirective {
  constructor() {
    console.warn('Directive nxImg which was used together with nxFigure ' + 'is now deprecated and not required anymore. Please remove it from your ' + 'tag. Check the documentation for more details.');
  }
  static {
    this.ɵfac = function NxImageDirective_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || NxImageDirective)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: NxImageDirective,
      selectors: [["img", "nxImg", ""]]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NxImageDirective, [{
    type: Directive,
    args: [{
      selector: 'img[nxImg]',
      standalone: true
    }]
  }], () => [], null);
})();
class NxImageModule {
  static {
    this.ɵfac = function NxImageModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || NxImageModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: NxImageModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NxImageModule, [{
    type: NgModule,
    args: [{
      imports: [NxFigureComponent, NxImageDirective],
      exports: [NxFigureComponent, NxImageDirective]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { NxFigureComponent, NxImageDirective, NxImageModule };
