import { Component, Input, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { ConstantsService } from '@app/core/services/base/constants.service';
import { ErrorService } from '@app/core/services/base/error.service';
import { UtilsService } from '@app/core/services/base/utils.service';

import { ConstantsMapModel, ConstantsModel } from '@app/shared/models/constant.model';
import { Constants } from '@app/core/constants/constants';

@Component({
    selector: 'azd-badge',
    templateUrl: './badge.component.html',
    styleUrls: ['./badge.component.scss'],
    standalone: false,
})
export class BadgeComponent implements OnInit {
    public constantsFE = Constants;
    public constantById!: ConstantsMapModel;
    public backgroundColor!: string;
    public label!: string;
    public smallLabel!: string;
    public iconTypeById!: ConstantsMapModel;

    @Input() constantType!: string;
    @Input() id!: number;
    @Input() isSmall!: boolean;
    @Input() withIcon!: string | null;
    @Input() isEmployeeEvent!: boolean;
    @Input() iconTypeId!: number | null;
    @Input() isReserved!: boolean;

    constructor(
        private constantsService: ConstantsService,
        private errorService: ErrorService,
        private utilsService: UtilsService,
    ) {}

    ngOnInit(): void {
        this.getConstants();
    }

    getConstants(): void {
        this.constantsService.getConstants().subscribe(
            (response: ConstantsModel) => {
                this.constantById = this.utilsService.arrayToObject(response[this.constantType], 'id');
                this.iconTypeById = this.utilsService.arrayToObject(response[Constants.EVENT_TYPE_ICONS], 'id');

                this.getBadgeDetail();
            },
            (error: HttpErrorResponse) => {
                this.errorService.openModalError(error, 'Errore nel caricamento delle constanti');
            },
        );
    }

    getBadgeDetail(): void {
        this.backgroundColor = this.isReserved ? '#ffffff' : '#' + (this.constantById[this.id].hex_code || '414141');
        this.label =
            'CONSTANTS.' +
            this.constantType +
            '.' +
            (this.isReserved
                ? Constants.EVENT_STATUSES_LIVE_SUBSCRIPTIONS_CLOSED
                : this.constantById[this.id].short_name || this.constantById[this.id].tag);
    }
}
